import { IBasicArticleBase } from "./IBasicArticle"

export interface ISearchArticle extends IBasicArticleBase {
  IDTaxe: number
  IDSaleUnit: number
  taxeCoeff: number
}

export class SearchArticle implements ISearchArticle {
  IDArt: number
  artType: string
  artName: string
  artRef: string
  artDesc: string
  artPVHT: number
  taxeName: string
  suName: string
  suDisplayName: string
  IDTaxe: number
  IDSaleUnit: number
  taxeCoeff: number

  constructor(article: ISearchArticle) {
    this.IDArt = article.IDArt
    this.artType = article.artType
    this.artName = article.artName
    this.artRef = article.artRef
    this.artDesc = article.artDesc
    this.artPVHT = article.artPVHT
    this.taxeName = article.taxeName
    this.suName = article.suName
    this.suDisplayName = article.suDisplayName
    this.IDTaxe = article.IDTaxe
    this.IDSaleUnit = article.IDSaleUnit
    this.taxeCoeff = article.taxeCoeff
  }
}
