import { ConfigProvider } from "antd"
import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import "./index.scss"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: "#35ce7e",
          colorInfo: "#35ce7e",
          colorSuccess: "#35ce7e",
          colorBgBase: "#eff1f5",
        },
        components: {
          Input: {
            activeBg: "#fff",
            colorBgContainer: "#fff",
          },
          InputNumber: {
            activeBg: "#fff",
            colorBgContainer: "#fff",
          },
          Select: {
            colorBgContainer: "#fff",
            colorBgElevated: "#fff",
          },
          Tabs: {
            colorBgContainer: "#fff",
          },
          Layout: {
            bodyBg: "#eff1f5",

            headerBg: "#eff1f5",

            footerBg: "#eff1f5",

            siderBg: "#eff1f5",
            triggerBg: "#35ce7e",
            triggerColor: "#35ce7e",
          },
          DatePicker: {
            colorBgContainer: "#fff",
          },
          Table: {
            colorBgContainer: "#fff",
          },
        },
      }}
    >
      <App />
    </ConfigProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
