import { notification, Statistic, Tag } from "antd"
import { useEffect, useState } from "react"
import { Calculator } from "react-flaticons"
import { EContent } from "../../../types/EContent"
import { IPageInfo } from "../../../types/IPageInfo"
import { TablePage } from "../../shared/form/pages/TablePage/TablePage"
import { EDevisStatus } from "../models/EDevisStatus"
import { IBasicDevis } from "../models/IBasicDevis"
import { IDevis } from "../models/IDevis"
import { DevisAPI } from "../services/Devis.api"
import { useDevisStore } from "../stores/Devis.store"

interface Props {
  onNew: () => void
  onEdit: (devisId: number) => void
  refresh: boolean
}

export const DevisTable: React.FC<Props> = ({ onNew, onEdit, refresh }) => {
  const [content, setContent] = useState<IPageInfo>()
  const [loading, setLoading] = useState(true)
  const [devis, setDevis] = useState<IBasicDevis[]>([])

  const devisStore = useDevisStore()

  useEffect(() => {
    setLoading(true)
    DevisAPI.getPageContent()
      .then((result) => {
        setContent(result.data)
        setTimeout(() => {
          setLoading(false)
        }, 500)
      })
      .catch(() => {
        notification.error({
          message: "Erreur",
          description: "Impossible de récupérer la description des devis",
        })
      })
  }, [])

  useEffect(() => {
    DevisAPI.getAllDevis()
      .then((res) => {
        if (res.ok) {
          const data = Object.values(res.data)
          devisStore.set(data)
          setDevis(
            data.map((contact) => ({
              ...contact,
              key: contact.IDDevis,
            })),
          )
        }
      })
      .catch((err) => {
        notification.error({
          message: "Erreur",
          description: "Impossible de récupérer la liste des devis",
        })
        console.log(err)
      })
  }, [refresh])

  const columnRules = {
    devisTotalHT: {
      align: "end",
      render: (price: number) =>
        price && <Statistic value={price} precision={2} groupSeparator=' ' suffix='€' />,
    },
    devisStatut: {
      render: (status: EDevisStatus) => status && <Tag color={status.color}>{status.name}</Tag>,
    },
  }

  return (
    <TablePage
      columnRules={columnRules}
      loading={loading}
      tableType={EContent.DEVIS}
      data={devis}
      icon={<Calculator />}
      onNew={onNew}
      onNewText={content?.btnTxt ?? "Nouveau devis"}
      title='Devis'
      pageDescription={content?.titre ?? ""}
      pageObjectives={content?.ssTitre ?? ""}
      whatCanBeDone={content?.texte.split("-").filter((t) => !!t) ?? []}
      onEdit={(devis: IDevis) => onEdit(devis.IDDevis)}
      filters={{
        devisStatut: {
          filters: [
            {
              text: <Tag color={EDevisStatus.NEW.color}>{EDevisStatus.NEW.name}</Tag>,
              value: EDevisStatus.NEW.name,
            },
            {
              text: <Tag color={EDevisStatus.SENT.color}>{EDevisStatus.SENT.name}</Tag>,
              value: EDevisStatus.SENT.name,
            },
            {
              text: <Tag color={EDevisStatus.ACCEPTED.color}>{EDevisStatus.ACCEPTED.name}</Tag>,
              value: EDevisStatus.ACCEPTED.name,
            },
            {
              text: <Tag color={EDevisStatus.INVOICE.color}>{EDevisStatus.INVOICE.name}</Tag>,
              value: EDevisStatus.INVOICE.name,
            },
            {
              text: <Tag color={EDevisStatus.REFUSED.color}>{EDevisStatus.REFUSED.name}</Tag>,
              value: EDevisStatus.REFUSED.name,
            },
          ],
          onFilter: (value: string, record: IBasicDevis) =>
            record.devisStatut.name.includes(value as string),
        },
      }}
    />
  )
}
