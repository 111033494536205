import { QuestionCircleOutlined } from '@ant-design/icons'
import { Space, Tooltip, Typography } from 'antd'

const { Text } = Typography

interface Props {
  text: string
  tooltip?: string
}

export const Label = ({ text, tooltip }: Props) => {
  return (
    <Space>
      <Text>{text}</Text>
      <Tooltip title={tooltip}>
        <QuestionCircleOutlined />
      </Tooltip>
    </Space>
  )
}
