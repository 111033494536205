import { IRow } from "../models/IDevis"

class DevisUtils {
  static computeSubTotal = (lines: IRow[]) => {
    return lines
      .filter((line) => !line.isTitle)
      .map(
        (line) =>
          line.price * line.quantity - line.discount.calculateDiscount(line.price * line.quantity),
      )
      .reduce((acc, curr) => acc + curr, 0)
  }

  /**
   * Sum the total price of each tax by name
   *
   * @returns an object with the sum of the total price of each tax by name
   */
  static sumTotalsByTaxName = (lines: IRow[]) => {
    return lines.reduce((acc: { [key: string]: number }, line) => {
      if (line.isTitle || line.tax.taxeCoeff === 0) {
        return acc
      }
      const taxName = `${line.tax.taxeName} (${line.tax.taxeCoeff}%)`
      const price =
        (line.price * line.quantity - line.discount.calculateDiscount(line.price * line.quantity)) *
        (line.tax.taxeCoeff / 100)
      acc[taxName] = acc[taxName] ? acc[taxName] + price : price
      return acc
    }, {})
  }

  static computeTaxAmount = (lines: IRow[]) => {
    const taxAmountByName = this.sumTotalsByTaxName(lines)
    return Object.values(taxAmountByName).reduce((acc, curr) => acc + curr, 0)
  }

  static computeTotal = (lines: IRow[]) => {
    const subTotal = this.computeSubTotal(lines)
    const taxAmount = this.computeTaxAmount(lines)
    return subTotal + taxAmount
  }
}

export default DevisUtils
