import { ColorPicker, Divider, Form, notification, Select, Tag } from "antd"
import { BaseOptionType } from "antd/es/select"
import { useEffect, useState } from "react"
import { CrossSmall } from "react-flaticons"
import { TagAPI } from "../../../../../../services/Tags.api"
import { EContent } from "../../../../../../types/EContent"
import { ITag } from "../../../../../../types/ITag"
import { EditAddTagForm } from "../EditAddTagForm"
import { TAG_COLORS } from "../model/tagColors"
import "./tagSelect.scss"

interface ITagPickerProps {
  module: EContent
}

interface ISelectProps {
  value: any
  label: any
  className: string
  deletable: number
}

export const TagSelect: React.FC<ITagPickerProps> = ({ module }) => {
  const [tags, setTags] = useState<ISelectProps[]>([])
  const [filteredTags, setFilteredTags] = useState<ISelectProps[]>([])
  const [showSelect, setShowSelect] = useState(false)
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false)

  useEffect(() => {
    TagAPI.getTags(module).then((res) => {
      if (res.ok) {
        const mappedTags = res.data.map((tag) => ({
          value: tag.IDTag,
          label: tag.tagName,
          className: tag.tagClassName,
          deletable: tag.deletable,
        }))
        setTags(mappedTags)
        setFilteredTags(mappedTags)
      } else {
        notification.error({
          message: "Erreur",
          description: "Impossible de récupérer la liste des étiquettes.",
        })
      }
    })
  }, [])

  const newTag = (tag: ITag) => {
    const newTagSelect = {
      value: tag.IDTag,
      label: tag.tagName,
      className: tag.tagClassName,
      deletable: tag.deletable,
    }
    setFilteredTags((tags) => [...tags, newTagSelect])
    setTags((tags) => [...tags, newTagSelect])
  }

  const updateTagColor = (tag: ISelectProps, color: string) => {
    TagAPI.updateTag({ IDTag: tag.value, tagName: tag.label, tagClassName: color }, module)
      .then((res) => {
        if (res.ok) {
          updateTags(res.data, tag.value)
        }
      })
      .catch(() => {
        notification.error({
          message: "Erreur",
          description: "Impossible de mettre à jour le tag.",
        })
      })
  }

  const updateTags = (tag: ITag, tagIdToReplace: number) => {
    const newTag = {
      value: tag.IDTag,
      label: tag.tagName,
      className: tag.tagClassName,
      deletable: tag.deletable,
    }

    setFilteredTags((tags) => [...tags.map((tag) => (tag.value !== tagIdToReplace ? tag : newTag))])
    setTags((tags) => tags.map((tag) => (tag.value !== tagIdToReplace ? tag : newTag)))
  }

  const selectTag = (tagIds_: number | number[]) => {
    const selectedTagIds = Array.isArray(tagIds_) ? tagIds_ : [tagIds_]
    setFilteredTags(tags.filter((tag) => !selectedTagIds.some((st) => st === tag.value)))
  }

  /**
   * Renders a selected tag
   * @param props CustomTagProps
   * @returns
   */
  const tagRender = (props: any) => {
    const tag = tags.find((t) => t.value === props.value)
    return (
      <Tag className='tag-selected' color={tag?.className ?? ""}>
        {tag?.label} <CrossSmall size={15} onClick={() => props.onClose()} />
      </Tag>
    )
  }

  const optionRender = (tag: BaseOptionType) => {
    return (
      <div className='tag-select-option'>
        {tag.data.deletable === 1 && (
          <ColorPicker
            trigger='hover'
            panelRender={() => {
              return (
                <div style={{ padding: "8px" }}>
                  {TAG_COLORS.map((color) => (
                    <Tag
                      key={color}
                      color={color === "grey" ? "" : color}
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation()
                        updateTagColor(tag.data as ISelectProps, color)
                      }}
                    >
                      {color}
                    </Tag>
                  ))}
                </div>
              )
            }}
            onOpenChange={(open) => setIsColorPickerOpen(open)}
            onChange={(_, hex) => updateTagColor(tag.data as ISelectProps, hex)}
          >
            <Tag className='tag-color-picker' color={tag.data.className} />
          </ColorPicker>
        )}
        <Tag color={tag.data.className}>{tag.label}</Tag>
      </div>
    )
  }

  const dropDownContentRender = () => {
    return (
      <>
        <Divider style={{ margin: "8px 0" }} />
        <EditAddTagForm module={module} onNewTag={newTag} />
      </>
    )
  }

  return (
    <Form.Item name='tags' label='Étiquettes'>
      <Select
        mode='multiple'
        placeholder='Étiquettes'
        options={filteredTags}
        open={showSelect}
        onClick={() => setShowSelect(true)}
        onDropdownVisibleChange={(e) => !isColorPickerOpen && setShowSelect(e)}
        onChange={(selectedTagIds: number | number[]) => selectTag(selectedTagIds)}
        notFoundContent={<div>Tous les tags sont sélectionnés</div>}
        showSearch
        filterOption={(inputValue, option) => {
          if (option?.label) {
            return option.label.toString().toLowerCase().includes(inputValue.toLowerCase())
          }
          return false
        }}
        tagRender={tagRender}
        optionRender={optionRender}
        dropdownRender={(menu) => (
          <>
            {menu}
            {dropDownContentRender()}
          </>
        )}
      />
    </Form.Item>
  )
}
