import { Button, Col, Drawer, Flex, Input, Row, Tooltip } from "antd"
import React, { createContext, useEffect, useState } from "react"
import { Settings as IconSetting, Search } from "react-flaticons"
import { ISetting, ISettings } from "../../models/ISetting"
import { settings as baseSettings } from "../../models/settings"
import { SettingGroup } from "../group/SettingGroup"
import "./settingDrawer.scss"

export const SettingContext = createContext<any>(() => {})

export const SettingDrawer = () => {
  const [showDrawer, setShowDrawer] = useState(false)
  const [settings, setSettings] = useState<React.ReactNode>([])
  const [SelectedSetting, setSetting] = useState<ISetting>()

  useEffect(() => {
    renderSettings(baseSettings)
  }, [])

  const setSelectedSettingGroup = (setting: ISetting) => {
    setSetting(setting)
  }

  const search = (text: string) => {
    const searchValue = text.trim().toLowerCase()
    const filteredSettingGroups = baseSettings.filter(
      (group) =>
        group.title.toLowerCase().includes(searchValue) ||
        group.settings.some((setting) => setting.label.toLowerCase().includes(searchValue)),
    )
    renderSettings(filteredSettingGroups, text)
  }

  const renderSettings = (settings: ISettings[], search?: string) => {
    setSettings(
      settings.map((setting, i) => <SettingGroup key={i} group={setting} highlightWord={search} />),
    )
  }

  return (
    <Flex align='center'>
      <Tooltip title='Paramètres'>
        <Button icon={<IconSetting />} onClick={() => setShowDrawer(true)} />
      </Tooltip>
      <SettingContext.Provider value={setSelectedSettingGroup}>
        <Drawer
          title='Paramètres'
          open={showDrawer}
          onClose={() => setShowDrawer(false)}
          className='setting-drawer'
          width={SelectedSetting ? "90%" : "15%"}
          afterOpenChange={(open) => !open && setSetting(undefined)}
        >
          <Row gutter={10}>
            <Col span={SelectedSetting ? 4 : 24}>
              <Input
                suffix={<Search size={15} />}
                placeholder='Rechercher dans les paramètres'
                onChange={(input) => search(input.target.value)}
              />
              <div className='setting-list'>{settings}</div>
            </Col>
            <Col span='20' className='setting'>
              {SelectedSetting && <SelectedSetting.modalContent />}
            </Col>
          </Row>
        </Drawer>
      </SettingContext.Provider>
    </Flex>
  )
}
