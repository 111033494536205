import { DefaultOptionType } from "antd/es/select"
import dayjs from "dayjs"
import { createContext, useContext, useEffect, useMemo, useState } from "react"
import { ArticleAPI } from "../../../services/Article.api"
import { ISaleUnit } from "../../../types/ISaleUnit"
import { SearchArticle } from "../../../types/ISearchArticle"
import { ITaxe } from "../../../types/ITaxe"
import { NDFSelectOptionType } from "../../shared/form/components/select/NDFSelect"
import { Discount, DiscountType, IRow } from "../models/IDevis"
import IDevisContact from "../models/IDevisContact"

interface IRowWithoutKeyAndIndex extends Omit<IRow, "key" | "index"> {
  key?: string
  index?: number
}

export interface ISelectOption<T = unknown> extends DefaultOptionType {
  data?: T
}

const EMPTY_ROW: IRowWithoutKeyAndIndex = {
  article: {
    IDArt: -1,
    taxeName: "",
    artName: "",
    artDesc: "",
  } as SearchArticle,
  quantity: 1,
  unit: { IDSaleUnit: -1, suDisplayName: "", suName: "" },
  price: 0,
  discount: new Discount(DiscountType.PERCENT, 0),
  tax: { IDTaxe: -1, taxeName: "", taxeCoeff: 0 },
  isTitle: false,
}

export const cloneRow = (row: IRowWithoutKeyAndIndex) => {
  return {
    ...row,
    article: { ...row.article },
    unit: { ...row.unit },
    tax: { ...row.tax },
  }
}

export const emptyRow = () => {
  return cloneRow(EMPTY_ROW)
}

interface DevisContextType {
  contact: IDevisContact
  setContact: (contact: IDevisContact) => void

  devisNumber: string
  creationDate: string
  validityDate: string
  title: string
  setDevisTitle: (title: string) => void

  lines: IRow[]
  setLines: (lines: IRowWithoutKeyAndIndex[], withExistingKeys?: boolean) => void
  addLine: (line?: IRowWithoutKeyAndIndex, fromIndex?: number) => void
  addTitle: (title: string, fromIndex?: number) => void

  additionalInformation: string
  termsAndConditions: string
  // TODO used type might not be correct
  uploadedFiles: File[]

  setArticle: (article: SearchArticle, index: number) => void

  taxes: ISelectOption<ITaxe>[]
  units: ISelectOption<ISaleUnit>[]

  subTotal: number
  discountTotal: number
  total: number

  setDiscount: (discount: number | null, type: DiscountType | null, index: number) => void
  setPrice: (price: number, index: number) => void
  setQuantity: (quantity: number, index: number) => void
  setUnit: (unit: ISaleUnit, index: number) => void
  setTax: (tax: ITaxe, index: number) => void
  setArticleName: (name: string, index: number) => void
  setArticleDescription: (description: string, index: number) => void
  setLineTitle: (title: string, index: number) => void

  articles: NDFSelectOptionType<SearchArticle>[]
  searchArticles: (search: string) => Promise<unknown>
}

const DevisContext = createContext<DevisContextType>({
  contact: {} as IDevisContact,
  setContact: () => {},

  devisNumber: "",
  creationDate: dayjs().toString(),
  validityDate: dayjs().add(1, "month").toString(),
  title: "",
  setDevisTitle: () => {},

  lines: [],
  setLines: () => {},
  addLine: () => {},
  addTitle: () => {},

  additionalInformation: "",
  termsAndConditions: "",
  uploadedFiles: [],

  setArticle: () => {},

  taxes: [],
  units: [],

  subTotal: 0,
  discountTotal: 0,
  total: 0,

  setDiscount: () => {},
  setPrice: () => {},
  setQuantity: () => {},
  setUnit: () => {},
  setTax: () => {},
  setArticleName: () => {},
  setArticleDescription: () => {},
  setLineTitle: () => {},

  articles: [],
  searchArticles: () => {
    return {} as Promise<unknown>
  },
})

export const useDevisContext = () => useContext(DevisContext)

const DevisContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [contact, setContact] = useState<IDevisContact>({} as IDevisContact)
  const [lines, setLines] = useState<IRow[]>([])
  let rowKeyIndex = 1
  const [title, setDevisTitle] = useState("")

  const [articles, setArticles] = useState<NDFSelectOptionType<SearchArticle>[]>([])
  const [taxes, setTaxes] = useState<ISelectOption<ITaxe>[]>([])
  const [units, setUnits] = useState<ISelectOption<ISaleUnit>[]>([])

  useEffect(() => {
    searchArticles()
    getTaxes()
    getSaleUnits()
  }, [])

  const searchArticles = async (search: string = "") => {
    if (search !== "" && search.length < 3) {
      return Promise.resolve()
    }
    return ArticleAPI.searchArticles(search).then((res) => {
      if (res.ok) {
        setArticles(
          res.data.map((article) => ({
            key: article.IDArt.toString(),
            label: article.artName,
            data: article,
          })),
        )
      }
    })
  }

  const getTaxes = () => {
    ArticleAPI.getTaxes().then((res) => {
      if (res.ok) {
        setTaxes(
          res.data.map((tax) => ({
            value: tax.IDTaxe,
            label: tax.taxeName,
            data: tax,
          })),
        )
      }
    })
  }

  const getSaleUnits = () => {
    ArticleAPI.getSaleUnits().then((res) => {
      if (res.ok) {
        setUnits(
          res.data.map((unit) => ({
            value: unit.IDSaleUnit,
            label: unit.suDisplayName,
            data: unit,
          })),
        )
      }
    })
  }

  const getKey = () => {
    return new Date().valueOf() + rowKeyIndex++ + ""
  }

  const addLine = (line?: IRowWithoutKeyAndIndex, index?: number) => {
    if (index !== undefined) {
      setAllLines(
        [
          ...lines.slice(0, index + 1),
          { ...(line ?? emptyRow()), key: getKey(), index: index + 1 },
          ...lines.slice(index + 1),
        ],
        true,
      )
      return
    }

    if (line) {
      setLines((current) => [
        ...current,
        { ...line, key: getKey(), index: line.index ?? current.length },
      ])
      return
    }

    setLines((current) => [
      ...current,
      { ...emptyRow(), key: getKey(), index: index ?? current.length },
    ])
  }

  const setAllLines = (lines: IRowWithoutKeyAndIndex[], withExistingKeys?: boolean) => {
    setLines(
      lines.map((line, i) => ({
        ...line,
        key: withExistingKeys ? line.key! : getKey(),
        index: i,
      })),
    )
  }

  const context = useMemo<DevisContextType>(
    () => ({
      contact: contact,
      setContact: (contact: IDevisContact) => setContact(contact),

      devisNumber: "",
      creationDate: dayjs().toString(),
      validityDate: dayjs().add(1, "month").toString(),
      title: title,
      setDevisTitle: (title: string) => setDevisTitle(title),

      lines: lines,
      setLines: (lines: IRowWithoutKeyAndIndex[], withExistingKeys?: boolean) =>
        setAllLines(lines, withExistingKeys),
      addLine: addLine,
      addTitle: (title: string, index?: number) =>
        addLine(
          {
            title,
            article: undefined as unknown as SearchArticle,
            quantity: 0,
            unit: undefined as unknown as ISaleUnit,
            price: 0,
            discount: new Discount(DiscountType.PERCENT, 0),
            tax: undefined as unknown as ITaxe,
            isTitle: true,
          },
          index,
        ),
      additionalInformation: "",
      termsAndConditions: "",
      uploadedFiles: [],

      taxes,
      units,

      // TODO this is not used
      subTotal: lines.reduce((acc, line) => {
        if (line.isTitle) {
          return acc
        }
        return acc + line.price * line.quantity
      }, 0),
      discountTotal: lines.reduce((acc, line) => {
        if (line.isTitle) {
          return acc
        }
        return acc + line.discount.calculateDiscount(line.price * line.quantity) * line.quantity
      }, 0),
      total: lines.reduce((acc, line) => {
        if (line.isTitle) {
          return acc
        }
        return (
          acc +
          (line.price * line.quantity -
            line.discount.calculateDiscount(line.price * line.quantity)) *
            (line.tax.taxeCoeff / 100)
        )
      }, 0),

      setArticle: (article: SearchArticle, index: number) => {
        setLines((current) => {
          if (!current[index]) {
            return current
          }

          const newLines = [...current]

          newLines[index].article = article
          newLines[index].quantity = 1
          newLines[index].price = article.artPVHT

          const tax = taxes.find((tax) => tax.data?.IDTaxe === article.IDTaxe)
          newLines[index].tax = tax?.data || ({} as ITaxe)

          const unit = units.find((unit) => unit.data?.suName === article.suName)
          newLines[index].unit = unit?.data || ({} as ISaleUnit)

          newLines[index].discount = new Discount(DiscountType.PERCENT, 0)

          newLines[index].isTitle = false

          return newLines
        })
      },

      setDiscount: (discount: number | null, type: DiscountType | null, index: number) => {
        setLines((current) => {
          const newLines = [...current]
          if (discount !== null && type !== null) {
            newLines[index].discount = new Discount(type, discount)
          }
          return newLines
        })
      },

      setPrice: (price: number, index: number) => {
        setLines((current) => {
          const newLines = [...current]
          newLines[index].price = price
          return newLines
        })
      },

      setQuantity: (quantity: number, index: number) => {
        setLines((current) => {
          const newLines = [...current]
          newLines[index].quantity = quantity
          return newLines
        })
      },

      setUnit: (unit: ISaleUnit, index: number) => {
        setLines((current) => {
          const newLines = [...current]
          newLines[index].unit = unit
          return newLines
        })
      },

      setTax: (tax: ITaxe, index: number) => {
        setLines((current) => {
          const newLines = [...current]
          newLines[index].tax = tax
          return newLines
        })
      },

      setArticleName: (name: string, index: number) => {
        setLines((current) => {
          const newLines = [...current]
          newLines[index].article.artName = name
          return newLines
        })
      },

      setArticleDescription: (description: string, index: number) => {
        setLines((current) => {
          const newLines = [...current]
          newLines[index].article.artDesc = description
          return newLines
        })
      },

      setLineTitle: (title: string, index: number) => {
        setLines((current) => {
          const newLines = [...current]
          newLines[index].title = title
          newLines[index].isTitle = true
          return newLines
        })
      },

      articles,
      searchArticles: (search: string) => searchArticles(search),
    }),
    [contact, setContact, articles, lines, title, taxes, units, setDevisTitle, searchArticles],
  )

  return <DevisContext.Provider value={context}>{children}</DevisContext.Provider>
}

export default DevisContextProvider
