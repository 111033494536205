import { ClearOutlined } from "@ant-design/icons"
import { Col, Divider, Form, Input, Modal, notification, Row, Select } from "antd"
import TextArea from "antd/es/input/TextArea"
import { useEffect, useState } from "react"
import { ContactAPI } from "../../../../services/Contacts.api"
import { Label } from "../../../shared/form/components/label/Label"
import { ContactFormContent } from "../ContactForm/ContactFormContent"
import "./linkedContactModal.scss"

interface Props {
  open: boolean
  linkedContactId?: number
  idTiers?: number
  onClose: () => void
  onOk: (contact: any) => void
}

export const LinkedContactModal = ({
  open = false,
  linkedContactId,
  idTiers,
  onClose,
  onOk,
}: Props) => {
  const [form] = Form.useForm()
  const [selectedContactId, setSelectedContactId] = useState<number>()
  const [contactNameIds, setContactNameIds] = useState<{ value: number; label: string }[]>()

  useEffect(() => {
    if (idTiers) {
      ContactAPI.getAvailableLinkedContacts(idTiers)
        .then((res) => {
          if (res.ok) {
            const contacts = res.data.map((contact) => ({
              value: contact.IDTiers,
              label: contact.Denomination,
            }))
            setContactNameIds(contacts)
          }
        })
        .catch(() => {
          notification.error({
            message: "Erreur",
            description: "Impossible de récupérer les contacts liés disponibles.",
          })
        })
    }
  }, [idTiers])

  useEffect(() => {
    if (linkedContactId && idTiers) {
      ContactAPI.getLinkedContact(idTiers, linkedContactId)
        .then((res) => {
          if (res.ok) {
            form.setFieldsValue(res.data)
          }
        })
        .catch(() => {
          notification.error({
            message: "Erreur",
            description: "Impossible de récupérer les informations du contact lié.",
          })
        })
    }
  }, [linkedContactId, idTiers])

  return (
    <Modal
      title={linkedContactId ? "Contact" : "Lier un contact"}
      open={open}
      onCancel={() => {
        onClose()
        setTimeout(() => {
          form.resetFields()
        }, 500)
      }}
      onOk={() => {
        const content = form.getFieldsValue()
        onOk({
          ...content,
          contactObs: content.contactObs ?? "",
          contactFonction: content.contactFonction ?? "",
        })
        setTimeout(() => {
          form.resetFields()
        }, 500)
      }}
      okText={linkedContactId ? "Mettre à jour" : "Lié un contact"}
      cancelText='Annuler'
      className='linked-contact-modal'
      width={"100%"}
    >
      <Form form={form} labelCol={{ span: 7 }} labelWrap labelAlign='left' layout='horizontal'>
        {!linkedContactId && (
          <>
            <Form.Item name='idContact'>
              <Select
                placeholder='Rechercher'
                notFoundContent={null}
                showSearch
                filterOption={(inputValue, option) => {
                  if (option?.label) {
                    return option.label.toString().toLowerCase().includes(inputValue.toLowerCase())
                  }
                  return false
                }}
                options={contactNameIds}
                allowClear={{ clearIcon: <ClearOutlined /> }}
                onSelect={(value: number) => setSelectedContactId(value)}
              />
            </Form.Item>
            <Divider />
          </>
        )}
        <ContactFormContent id={selectedContactId} onLoad={() => {}} />
        <Divider />
        <Row>
          <Col span={24}>
            <Form.Item
              labelCol={{ span: 3, sm: 5, md: 4, xl: 3 }}
              name='contactFonction'
              label={
                <Label
                  text='Fonction'
                  tooltip='Indiquez ici la fonction qu’occupe ce contact dans l’entreprise sélectionnée.'
                />
              }
            >
              <Input placeholder='Fonction' />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              labelCol={{ span: 3, sm: 5, md: 4, xl: 3 }}
              name='contactObs'
              label='Observations'
            >
              <TextArea placeholder='Observations' />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
