import { Tabs } from "antd"
import { useEffect, useState } from "react"
import { ITabs } from "../../../../../types/ITabs"
import "./formSubModule.scss"

interface IFormSubModuleProps {
  tabs: ITabs[]
}

export const FormSubModule: React.FC<IFormSubModuleProps> = ({ tabs }) => {
  const [activeTabKey, setActiveTabKey] = useState("0")

  useEffect(() => {
    setActiveTabKey("0")
  }, [tabs])

  return (
    <Tabs
      className='form-submodule-tabs'
      defaultActiveKey='0'
      activeKey={activeTabKey}
      type='card'
      onTabClick={(key) => setActiveTabKey(key)}
      items={tabs.map((tab, i) => ({
        label: tab.label,
        key: i.toString(),
        children: tab.content,
      }))}
    />
  )
}
