import { Form } from "antd"
import { useEffect, useState } from "react"
import { ContactAPI } from "../../../../../../services/Contacts.api"
import { ContactForm } from "../../../../../contact/components/ContactForm/ContactForm"
import IDevisContact, { DevisContact } from "../../../../../devis/models/IDevisContact"
import NDFSelect, { NDFSelectOptionType } from "../NDFSelect"
import "./contactSearch.scss"

interface Props {
  onSelect: (contact: IDevisContact) => void
  label: string
  value: IDevisContact | undefined
}

export const ContactSearch: React.FC<Props> = ({ onSelect, value, label }) => {
  const [contactNameIds, setContactNameIds] = useState<NDFSelectOptionType<IDevisContact>[]>([])
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState("")
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    setLoading(true)

    const timeout = setTimeout(() => {
      searchContact(search)
    }, 500)

    return () => {
      clearTimeout(timeout)
      setLoading(false)
    }
  }, [search])

  const searchContact = (search: string) => {
    if (search.length && search.length < 3) {
      return
    }
    return ContactAPI.searchForContact(search).then((res) => {
      if (res.ok) {
        const contacts = res.data?.map((contact) => ({
          key: contact.IDTiers,
          label: contact.tiersDenomination,
          data: contact,
        }))
        setContactNameIds(contacts)
      }
      setLoading(false)
    })
  }

  const onContactSelect = (contact: IDevisContact) => {
    // TODO : don't make a request when selecting a contact
    setSearch(contact.tiersDenomination)
    onSelect && onSelect(contact)
  }

  return (
    <>
      <Form.Item className='contact-search' label={label}>
        <NDFSelect<IDevisContact>
          search={search}
          options={contactNameIds}
          onSearch={setSearch}
          onSelect={onContactSelect}
          value={value ? value.tiersDenomination : undefined}
          isLoading={loading}
          onNew={() => setShowModal(true)}
          placeholder={{ notFound: "Aucun contact trouvé", search: "Recherchez un contact" }}
        />
      </Form.Item>
      <ContactForm
        id={undefined}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onSubmit={(contact) => {
          setShowModal(false)
          if (contact) {
            const newContact = new DevisContact(contact)
            onSelect && onSelect(newContact)
            searchContact(newContact.tiersDenomination)
          }
        }}
      />
    </>
  )
}
