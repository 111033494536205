import Highlighter from "react-highlight-words"

interface Props {
  textToHighlight: string
  search: string
}

export const Highlight = ({ textToHighlight, search }: Props) => {
  return (
    <Highlighter
      highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
      searchWords={[search]}
      autoEscape={true}
      textToHighlight={textToHighlight}
    />
  )
}
