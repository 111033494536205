import { Button, Flex, Modal, Transfer, TransferProps } from 'antd'
import { TransferItem } from 'antd/es/transfer'
import { FC, useEffect, useState } from 'react'
import { AngleSmallDown, AngleSmallUp } from 'react-flaticons'
import { UtilAPI } from '../../../../../services/Utils.api'
import { useUser } from '../../../../../stores/User.store'
import { EContent } from '../../../../../types/EContent'
import { ITableColumns } from '../../../../../types/ITableColumn'
import './columnSelector.scss'

interface Props {
  open: boolean
  onClose: () => void
  columns?: ITableColumns
  tableType: EContent
  onChange: (columns: string[]) => void
}

export const ColumnSelector: FC<Props> = ({ columns, open, onClose, tableType, onChange }) => {
  const [targetKeys, setTargetKeys] = useState<TransferProps['targetKeys']>([])
  const [formattedColumns, setFormattedColumns] = useState<TransferItem[]>([])
  const [selectedTargetColumns, setSelectedTargetColumns] = useState<string[]>([])
  const [selectedSourceColumns, setSelectedSourceColumns] = useState<string[]>([])
  const user = useUser()

  useEffect(() => {
    setTargetKeys(columns?.userColumns?.map((column) => String(column.IDColumn)) ?? [])
    setFormattedColumns(
      columns?.allcolumns?.map((column) => ({
        key: String(column.IDColumn),
        title: column.title,
        description: column.field,
      })) ?? [],
    )
  }, [columns])

  const onSelectChange: TransferProps['onChange'] = (nextTargetKeys) => {
    setTargetKeys(nextTargetKeys)
  }

  function arrayMove(arr: string[], fromIndex: number, toIndex: number, amount = 1) {
    if (toIndex >= arr.length || toIndex < 0) {
      return
    }
    const element = arr.slice(fromIndex, fromIndex + amount)
    arr.splice(fromIndex, 1)
    arr.splice(toIndex, 0, ...element)
  }

  const moveColumn = (moveUp: boolean) => {
    const movedTargetKeys = [...(targetKeys ?? [])]
    for (
      let index = moveUp ? 0 : movedTargetKeys.length;
      moveUp ? index < movedTargetKeys.length : index > -1;
      moveUp ? index++ : index--
    ) {
      const col = targetKeys?.at(index)
      if (col && targetKeys && selectedTargetColumns.includes(String(col))) {
        const keyIndex = movedTargetKeys.indexOf(col)
        arrayMove(movedTargetKeys, keyIndex, keyIndex + (moveUp ? -1 : 1))
      }
    }
    setTargetKeys(movedTargetKeys)
  }

  const resetColumns = () => {
    const defaultColumns = columns?.allcolumns.filter((column) => column.isDefault)
    setSelectedSourceColumns([])
    setSelectedTargetColumns([])
    setTargetKeys(defaultColumns?.map((column) => String(column.IDColumn)) ?? [])
  }

  const save = () => {
    UtilAPI.saveTableColumns(tableType, user.IDUser, targetKeys ?? [])
    onChange(targetKeys ?? [])
  }

  return (
    <Modal
      title='Paramétrage des colonnes'
      open={open}
      onCancel={() => {
        onClose()
        setSelectedTargetColumns([])
      }}
      footer={null}
      className='column-selector-modal'
      centered
    >
      <Flex vertical align='center' gap={20}>
        <Flex gap={10}>
          <Button
            icon={<AngleSmallUp />}
            disabled={selectedTargetColumns.length === 0}
            onClick={() => moveColumn(true)}
          ></Button>
          <Button
            icon={<AngleSmallDown />}
            disabled={selectedTargetColumns.length === 0}
            onClick={() => moveColumn(false)}
          ></Button>
        </Flex>
        <Transfer
          selectedKeys={[...selectedSourceColumns, ...selectedTargetColumns]}
          style={{ margin: 0 }}
          listStyle={{ minWidth: '20px' }}
          dataSource={formattedColumns}
          titles={['Disponibles', 'Sélectionnés']}
          targetKeys={targetKeys}
          onChange={onSelectChange}
          onSelectChange={(sourceSelectedKeys, targetSelectedKeys) => {
            setSelectedTargetColumns(targetSelectedKeys)
            setSelectedSourceColumns(sourceSelectedKeys)
          }}
          locale={{
            notFoundContent: 'Aucune colonne',
          }}
          render={(item) => <div>{item.title}</div>}
        />
        <Flex gap={10}>
          <Button type='default' onClick={resetColumns}>
            Réinitialiser
          </Button>
          <Button type='primary' onClick={save}>
            Enregistrer
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}
