import { Avatar, Button, Flex, Popover, Typography } from 'antd'
import { SignOutAlt } from 'react-flaticons'
import { useNavigate } from 'react-router-dom'
import { useUserStore } from '../../../../../stores/User.store'
import { IUser } from '../../../../../types/IUser'

interface IUserIconProps {
  user: IUser
}

export const UserIcon: React.FC<IUserIconProps> = ({ user }) => {
  const userStore = useUserStore()
  const navigate = useNavigate()

  const handleLogout = () => {
    navigate('/public/login')
    userStore.logout()
  }

  const userMenuContent = (
    <>
      <Typography.Text>{user.userLastName}</Typography.Text>
      <Flex justify='space-between' align='center' vertical>
        <Button icon={<SignOutAlt />} onClick={handleLogout}>
          Déconnexion
        </Button>
      </Flex>
    </>
  )

  return (
    <div className='user-icon'>
      <Popover placement='bottomLeft' content={userMenuContent} trigger='click'>
        <Flex className='userMenu'>
          <Avatar size='large' src={user.userAvatar}></Avatar>
        </Flex>
      </Popover>
    </div>
  )
}
