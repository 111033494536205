import { notification, Popconfirm } from "antd"
import { MenuInfo } from "rc-menu/lib/interface"
import { FC, useEffect, useState } from "react"
import { ArticleAPI } from "../../../../services/Article.api"
import { useArticles } from "../../../../stores/Article.store"
import { ESuiviObject } from "../../../../types/ESuiviObject"
import { IArticle } from "../../../../types/IArticle"
import { IArticleForm } from "../../../../types/IArticleForm"
import IFormProps from "../../../../types/IFormProps"
import { NDFForm } from "../../../shared/form/pages/Form/Form"
import { ArticleFormContent } from "./ArticleFormContent"
import "./articleForm.scss"

export const ArticleForm: FC<IFormProps<IArticle>> = ({ id, isOpen, onClose, onSubmit }) => {
  const [articleId, setArticleId] = useState(id)
  const [loading, setLoading] = useState(true)
  const articles = useArticles()

  useEffect(() => {
    setArticleId(id)
  }, [id])

  const onFormSubmit = async (articleForm: IArticleForm) => {
    articleForm.artType = articleForm.artType ?? ""
    articleForm.artName = articleForm.artName ?? ""
    articleForm.IDSaleUnit = articleForm.IDSaleUnit ?? ""
    articleForm.artPVHT = articleForm.artPVHT ?? ""
    articleForm.IDTaxe = articleForm.IDTaxe ?? ""
    articleForm.artRef = articleForm.artRef ?? ""
    articleForm.artDesc = articleForm.artDesc ?? ""

    articleForm.tags?.forEach((tag: number) => {
      // @ts-expect-error stop complaining
      articleForm[`tags[${tag}]`] = tag
    })
    delete articleForm.tags

    if (articleId) {
      return ArticleAPI.update({ ...articleForm, IDArt: articleId })
        .then((res) => {
          if (res.ok) {
            onSubmit(res.data)
            notification.success({
              message: "Succès",
              description: `Article ${res.data.artName} mis à jour avec succès`,
            })
            return true
          }
          return false
        })
        .catch((err) => {
          notification.error({
            message: "Erreur",
            description: `Impossible de mettre à jour cette article. Erreur: ${JSON.stringify(err)}`,
          })
          return false
        })
    }

    return ArticleAPI.create(articleForm)
      .then((res) => {
        if (res.ok) {
          onSubmit(res.data)
          notification.success({
            message: "Succès",
            description: `Article ${res.data.artName} créé avec succès`,
          })
          return true
        }
        return false
      })
      .catch((err) => {
        notification.error({
          message: "Erreur",
          description: `Impossible de créer l'article. Erreur: ${JSON.stringify(err)}`,
        })
        return false
      })
  }

  // TODO: This depends if we only need a delete action or multiple actions
  const onActionClick = (action: MenuInfo) => {
    switch (action.key) {
      case "1":
        // console.log('supprimer')
        // if (contactId) {
        //   ContactAPI.deleteContact(contactId)
        //   onClose()
        // }
        break
      default:
        break
    }
  }

  const deleteArticle = () => {
    if (articleId) {
      ArticleAPI.delete(articleId)
      onClose()
    }
  }

  const formActions = [
    {
      label: (
        <Popconfirm
          title="Supprimer l'article"
          description='Êtes-vous sûr de vouloir supprimer cet article ?'
          onConfirm={deleteArticle}
          okText='Oui'
          cancelText='Non'
          okType='danger'
          icon={null}
        >
          Supprimer
        </Popconfirm>
      ),
      key: "1",
      danger: true,
    },
  ]

  return (
    <NDFForm
      title={articleId ? "Mise à jour" : "Nouvel article"}
      onClose={onClose}
      onSubmit={onFormSubmit}
      next={() => {
        const currentArticleIndex = articles.findIndex((c) => c.IDArt === articleId)
        const article =
          articles.length === currentArticleIndex + 1
            ? articles.at(0)
            : articles.at(currentArticleIndex + 1)
        if (article) {
          setArticleId(article.IDArt)
        }
      }}
      previous={() => {
        const nextArticleIndex = articles.findIndex((c) => c.IDArt === articleId) - 1
        const article = articles.at(nextArticleIndex)
        if (article) {
          setArticleId(article.IDArt)
        }
      }}
      isOpen={isOpen}
      loading={loading}
      actions={articleId ? formActions : []}
      onActionClick={onActionClick}
      objectId={articleId}
      activityType={ESuiviObject.ARTICLES}
    >
      <ArticleFormContent id={articleId} onLoad={setLoading} />
    </NDFForm>
  )
}
