import { Flex, Form, Input, InputNumber, Modal, notification, Space } from "antd"
import React, { useEffect, useState } from "react"
import { ArticleAPI } from "../../../../services/Article.api"
import { ISaleUnit } from "../../../../types/ISaleUnit"
import { ITaxe as ITax } from "../../../../types/ITaxe"
import { ButtonDelete } from "../../../shared/utils/components/ButtonDelete/ButtonDelete"
import { ButtonNew } from "../../../shared/utils/components/ButtonNew/ButtonNew"
import { Table } from "../../../shared/utils/components/Table/Table"
import "./articleTaxSetting.scss"

const COLUMNS = [
  {
    title: "Libellé",
    field: "taxeName",
  },
  {
    title: "Pourcentage",
    field: "taxeCoeff",
  },
]

const columns = COLUMNS.map((col) => ({
  key: col.field,
  title: col.title,
  dataIndex: col.field,
  width: `${100 / COLUMNS.length}%`,
  sorter: (a: any, b: any) => {
    const strA = String(a[col.field]).trim().toLowerCase()
    const strB = String(b[col.field]).trim().toLowerCase()
    if (strA === "") return 1
    if (strB === "") return -1
    return strA.localeCompare(strB)
  },
  render: (value: string, record: ISaleUnit) => {
    if (col.field === "taxeCoeff") {
      return <div>{value}%</div>
    }
    return <div>{value}</div>
  },
}))

export const ArticleTaxSetting: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [taxes, setTaxes] = useState<ITax[]>([])
  const [showModal, setShowModal] = useState(false)
  const [selectedTaxId, setSelectedTaxId] = useState<number>()
  const [selectedTaxIds, setSelectedTaxIds] = useState<number[]>([])

  const [form] = Form.useForm()

  useEffect(() => {
    getTaxes()
  }, [])

  const getTaxes = () => {
    ArticleAPI.getTaxes()
      .then((res) => {
        setTaxes(res.data.map((tax) => ({ ...tax, key: tax.IDTaxe })))
      })
      .catch((err) => {
        notification.error({
          message: "Erreur lors de la récupération des taxes",
          description: err.message,
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const deleteTaxes = () => {
    const promises = selectedTaxIds.map((id) => ArticleAPI.deleteTax(id))
    Promise.all(promises)
      .then(() => {
        notification.success({
          message: promises.length > 1 ? "Taxes supprimées" : "Taxe supprimée",
        })
      })
      .catch((err) => {
        notification.error({
          message:
            promises.length > 1
              ? "Erreur lors de la suppression des taxes"
              : "Erreur lors de la suppression de la taxe",
          description: err.message,
        })
      })
      .finally(() => {
        getTaxes()
      })
  }

  const createOrUpdate = () => {
    if (selectedTaxId) {
      ArticleAPI.updateTax({ ...form.getFieldsValue(), IDTaxe: selectedTaxId })
        .then(() => {
          notification.success({
            message: "Taxe mise à jour",
          })
        })
        .catch((err) => {
          notification.error({
            message: "Erreur lors de la mise à jour de la taxe",
            description: err.message,
          })
        })
        .finally(() => {
          getTaxes()
          form.resetFields()
          setSelectedTaxId(undefined)
        })
    } else {
      ArticleAPI.createTax(form.getFieldsValue())
        .then(() => {
          notification.success({
            message: "Taxe créée",
          })
        })
        .catch((err) => {
          notification.error({
            message: "Erreur lors de la création de la taxe",
            description: err.message,
          })
        })
        .finally(() => {
          getTaxes()
          form.resetFields()
          setSelectedTaxId(undefined)
        })
    }
  }

  return (
    <>
      <Flex>
        <Table
          header={() => (
            <Flex justify='flex-end'>
              <Space>
                <ButtonDelete onClick={deleteTaxes} />
                <ButtonNew onClick={() => setShowModal(true)} />
              </Space>
            </Flex>
          )}
          loading={loading}
          columnsToDisplay={columns}
          data={taxes}
          noData={() => <div>Aucune taxes</div>}
          onEdit={(tax: ITax) => {
            setSelectedTaxId(tax.IDTaxe)
            setShowModal(true)
            form.setFieldsValue(tax)
          }}
          pagination={false}
          onRowSelection={(selectedRowKeys) => {
            setSelectedTaxIds(selectedRowKeys as number[])
          }}
        />
      </Flex>
      {showModal && (
        <Modal
          title={selectedTaxId ? "Mettre à jour" : "Créer une taxe"}
          open={showModal}
          onCancel={() => {
            setShowModal(false)
            setSelectedTaxId(undefined)
            form.resetFields()
          }}
          onOk={() => {
            setShowModal(false)
            createOrUpdate()
          }}
          cancelText='Annuler'
          okText='Enregistrer'
          className='article-tax-setting-modal'
        >
          <Form
            form={form}
            labelCol={{ xs: 24, sm: 5, md: 6, lg: 5, xl: 7, xxl: 6 }}
            labelWrap
            labelAlign='left'
            layout='horizontal'
          >
            <Form.Item label='Libellé' name='taxeName'>
              <Input />
            </Form.Item>
            <Form.Item label='Pourcentage' name='taxeCoeff'>
              <InputNumber
                addonAfter={"%"}
                parser={(value) => value?.replace(/,/g, ".") as unknown as number}
                precision={0}
                min={0}
              />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  )
}
