import { Flex, Form, Input, Modal, notification, Space } from "antd"
import { useEffect, useState } from "react"
import { ArticleAPI } from "../../../../services/Article.api"
import { ISaleUnit } from "../../../../types/ISaleUnit"
import { ButtonDelete } from "../../../shared/utils/components/ButtonDelete/ButtonDelete"
import { ButtonNew } from "../../../shared/utils/components/ButtonNew/ButtonNew"
import { Table } from "../../../shared/utils/components/Table/Table"

const COLUMNS = [
  {
    title: "Libellé",
    field: "suName",
  },
  {
    title: "Affichage",
    field: "suDisplayName",
  },
]

const columns = COLUMNS.map((col) => ({
  key: col.field,
  title: col.title,
  dataIndex: col.field,
  width: `${100 / COLUMNS.length}%`,
  sorter: (a: any, b: any) => {
    const strA = String(a[col.field]).trim().toLowerCase()
    const strB = String(b[col.field]).trim().toLowerCase()
    if (strA === "") return 1
    if (strB === "") return -1
    return strA.localeCompare(strB)
  },
}))

export const ArticleSaleUnitSetting: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [units, setUnits] = useState<ISaleUnit[]>([])
  const [showModal, setShowModal] = useState(false)
  const [selectedSaleUnitId, setSelectedSaleUnitId] = useState<number>()
  const [selectedSaleUnitIds, setSelectedSaleUnitIds] = useState<number[]>([])

  const [form] = Form.useForm()

  useEffect(() => {
    getSaleUnits()
  }, [])

  const getSaleUnits = () => {
    ArticleAPI.getSaleUnits()
      .then((res) => {
        setUnits(res.data.map((unit) => ({ ...unit, key: unit.IDSaleUnit })))
      })
      .catch((err) => {
        notification.error({
          message: "Erreur lors de la récupération des unités de vente",
          description: err.message,
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const deleteSaleUnits = () => {
    const promises = selectedSaleUnitIds.map((id) => ArticleAPI.deleteSaleUnit(id))
    Promise.all(promises)
      .then(() => {
        notification.success({
          message: promises.length > 1 ? "Unités de ventes supprimées" : "Unité de vente supprimée",
        })
      })
      .catch((err) => {
        notification.error({
          message: promises.length > 1
            ? "Erreur lors de la suppression des unités de ventes"
            : "Erreur lors de la suppression de l'unité de vente",
          description: err.message,
        })
      })
      .finally(() => {
        getSaleUnits()
      })
  }

  const createOrUpdate = () => {
    if (selectedSaleUnitId) {
      ArticleAPI.updateSaleUnit({ ...form.getFieldsValue(), IDSaleUnit: selectedSaleUnitId })
        .then(() => {
          notification.success({
            message: "Unité de vente mise à jour",
          })
        })
        .catch((err) => {
          notification.error({
            message: "Erreur lors de la mise à jour de l'unité de vente",
            description: err.message,
          })
        })
        .finally(() => {
          getSaleUnits()
          form.resetFields()
          setSelectedSaleUnitId(undefined)
        })
    } else {
      ArticleAPI.createSaleUnit(form.getFieldsValue())
        .then(() => {
          notification.success({
            message: "Unité de vente créée",
          })
        })
        .catch((err) => {
          notification.error({
            message: "Erreur lors de la création de l'unité de vente",
            description: err.message,
          })
        })
        .finally(() => {
          getSaleUnits()
          form.resetFields()
          setSelectedSaleUnitId(undefined)
        })
    }
  }

  return (
    <>
      <Flex>
        <Table
          header={() => (
            <Flex justify='flex-end'>
              <Space>
                <ButtonDelete onClick={deleteSaleUnits} />
                <ButtonNew onClick={() => setShowModal(true)} />
              </Space>
            </Flex>
          )}
          loading={loading}
          columnsToDisplay={columns}
          data={units}
          noData={() => <div>Aucune unité de vente</div>}
          onEdit={(unit: ISaleUnit) => {
            setSelectedSaleUnitId(unit.IDSaleUnit)
            setShowModal(true)
            form.setFieldsValue(unit)
          }}
          pagination={false}
          onRowSelection={(selectedRowKeys) => {
            setSelectedSaleUnitIds(selectedRowKeys as number[])
          }}
        />
      </Flex>
      {showModal && (
        <Modal
          title={selectedSaleUnitId ? "Mettre à jour" : "Créer une unité de vente"}
          open={showModal}
          onCancel={() => {
            setShowModal(false)
            setSelectedSaleUnitId(undefined)
            form.resetFields()
          }}
          onOk={() => {
            setShowModal(false)
            createOrUpdate()
          }}
          cancelText='Annuler'
          okText='Enregistrer'
        >
          <Form
            form={form}
            labelCol={{ xs: 24, sm: 5, md: 6, lg: 5, xl: 7, xxl: 6 }}
            labelWrap
            labelAlign='left'
            layout='horizontal'
          >
            <Form.Item label='Libellé' name='suName'>
              <Input />
            </Form.Item>
            <Form.Item label='Affichage' name='suDisplayName'>
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  )
}
