import { Table as Table_ } from "antd"
import { RowSelectionType } from "antd/es/table/interface"
import "./table.scss"

interface Props {
  header?: any
  loading: boolean
  data: any[]
  columnsToDisplay: any[]
  onEdit: (record: any) => void
  noData: () => any
  pagination?: boolean
  allowSelection?: boolean
  onRowSelection?: (selectedRowKeys: React.Key[], selectedRows: any[]) => void
}

export const Table = ({
  header,
  loading,
  data,
  columnsToDisplay,
  onEdit,
  noData,
  pagination = true,
  allowSelection = true,
  onRowSelection,
}: Props) => {
  const props = allowSelection
    ? {
        rowSelection: {
          type: "checkbox" as RowSelectionType,
          selections: [Table_.SELECTION_ALL, Table_.SELECTION_INVERT, Table_.SELECTION_NONE],
          onChange: onRowSelection,
          getCheckboxProps: (record: any) => ({
            disabled: record?.disableSelection, // Column configuration not to be checked
          }),
        },
      }
    : {}

  return (
    <Table_
      scroll={{ y: "calc(100% - 105px)", x: data.length === 0 ? "min-content" : "max-content" }}
      title={header}
      loading={loading}
      className={
        "ndf-table" +
        (data.length === 0 ? " table-no-data" : "") +
        (allowSelection ? " table-selectable" : "")
      }
      showHeader={data.length > 0}
      columns={columnsToDisplay ?? []}
      dataSource={data}
      onRow={(record) => {
        return {
          onDoubleClick: () => {
            onEdit(record)
          },
        }
      }}
      locale={{
        emptyText: noData(),
        selectionAll: "Tout sélectionner",
        selectAll: "Sélectionner la page",
        selectInvert: "Inverser la sélection",
        selectNone: "Désélectionner tout",
        triggerAsc: "Cliquer pour trier par ordre croissant",
        triggerDesc: "Cliquer pour trier par ordre décroissant",
        cancelSort: "Cliquer pour annuler le tri",
        filterReset: "Effacer",
        filterConfirm: "Rechercher",
      }}
      pagination={
        pagination && {
          showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} éléments`,
        }
      }
      size='middle'
      {...props}
    />
  )
}
