import { ClearOutlined } from "@ant-design/icons"
import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Space,
} from "antd"
import { BaseOptionType } from "antd/es/select"
import { useEffect, useState } from "react"
import { Trash } from "react-flaticons"
import { ContactAPI } from "../../../../services/Contacts.api"
import { EContactType } from "../../../../types/EContactType"
import { EContent } from "../../../../types/EContent"
import { IContactForm } from "../../../../types/IContactForm"
import { IFormContentProps } from "../../../../types/IFormProps"
import { ITag } from "../../../../types/ITag"
import { TagSelect } from "../../../shared/form/components/tags/tag-select/TagSelect"

export const ContactFormContent: React.FC<IFormContentProps> = ({ id: contactId, onLoad }) => {
  const [companies, setCompanies] = useState<BaseOptionType[]>([])
  const [isContactACompany, setIsContactACompany] = useState(false)

  const form = Form.useFormInstance<IContactForm>()

  useEffect(() => {
    ContactAPI.getCompanies().then((res) => {
      if (res.ok) {
        setCompanies(
          res.data.map((company) => ({
            value: company.IDTiers,
            label: company.tiersDenomination,
          })),
        )
      } else {
        notification.error({
          message: "Erreur",
          description: "Impossible de récupérer la liste des entreprises.",
        })
      }
    })

    return setIsContactACompany(false)
  }, [])

  useEffect(() => {
    setFormData()
  }, [contactId])

  const setFormData = () => {
    onLoad(true)

    if (!contactId) {
      form.setFieldsValue({
        nom: "",
        prenom: "",
        denomination: "",
        IDEnt: "",
        numTVA: "",
        fonction: "",
        website: "",
        tags: [],
        notes: "",
      })
      onLoad(false)
      return
    }

    ContactAPI.getContact(contactId).then((res) => {
      setIsContactACompany(res.data.companyType === EContactType.ENTREPRISE)
      form.setFieldsValue({
        ...res.data,
        tags: res.data.tags.map((tag: ITag) => tag.IDTag),
        companyType: res.data.companyType,
        IDEnt: res.data.enterprise?.entName,
      })
      onLoad(false)
    })
  }

  const changeFormType = (e: RadioChangeEvent) => {
    setIsContactACompany(e.target.value === EContactType.ENTREPRISE)
    form.setFieldsValue({
      nom: undefined,
      prenom: undefined,
      denomination: undefined,
      IDEnt: undefined,
      numTVA: undefined,
      fonction: undefined,
    })
  }

  const removeRelation = () => {
    if (form.getFieldValue("IDTiers")) {
      ContactAPI.removeCompanyPersonRelation(
        form.getFieldValue("IDEnt"),
        form.getFieldValue("IDTiers"),
      )
    }
    form.setFieldsValue({ IDEnt: undefined })
  }

  const personForm = () => {
    return (
      <>
        <Form.Item label='Contact'>
          <Space direction='horizontal'>
            <Form.Item name='nom' noStyle>
              <Input placeholder='Nom' />
            </Form.Item>
            <Form.Item name='prenom' noStyle>
              <Input placeholder='Prénom' />
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item label='Entreprise' className='entreprise-select'>
          <Space align='start'>
            <Form.Item name='IDEnt'>
              <Select
                placeholder='Nom de la société'
                notFoundContent={null}
                showSearch
                filterOption={(inputValue, option) => {
                  if (option?.label) {
                    return option.label.toString().toLowerCase().includes(inputValue.toLowerCase())
                  }
                  return false
                }}
                options={companies}
                onClear={removeRelation}
                allowClear={{ clearIcon: <ClearOutlined /> }}
              />
            </Form.Item>
            <Button
              type='default'
              className='remove-relation-button'
              onClick={removeRelation}
              icon={<Trash size='14' />}
            />
          </Space>
        </Form.Item>
        {!isContactACompany && (
          <Form.Item name='fonction' label='Fonction' className='contact-fonction'>
            <Input placeholder='Fonction' />
          </Form.Item>
        )}
      </>
    )
  }

  const companyForm = () => {
    return (
      <>
        <Form.Item label='Nom' name='denomination'>
          <Input placeholder="Denomination de l'entreprise" />
        </Form.Item>
        <Form.Item label='N° de TVA' name='numTVA'>
          <Input placeholder='N° de TVA' />
        </Form.Item>
      </>
    )
  }

  return (
    <Col className='contact-form'>
      <Row>
        <Col xs={24} xl={12}>
          <Form.Item name='companyType' label='Type' initialValue={EContactType.PARTICULIER}>
            <Radio.Group onChange={changeFormType}>
              <Radio value={EContactType.PARTICULIER}>Particulier</Radio>
              <Radio value={EContactType.ENTREPRISE}>Entreprise</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 10, xl: 50 }} wrap>
        <Col xs={24} xl={12}>
          {isContactACompany ? companyForm() : personForm()}
        </Col>
        <Col xs={24} xl={12}>
          <Form.Item name='website' label='Site Web' className='contact-website'>
            <Input placeholder='Site Web' />
          </Form.Item>
          <div className='contact-tags'>
            <TagSelect module={EContent.CONTACTS} />
          </div>
        </Col>
      </Row>
    </Col>
  )
}
