import { Col, Flex, Row } from 'antd'
import { Footer, Header } from 'antd/es/layout/layout'
import { LoginCard } from '../components/LoginCard/LoginCard'
import { LoginInfo } from '../components/LoginInfo/LoginInfo'
import './login.scss'

export const Login: React.FC = () => {
  return (
    <Flex vertical className='login'>
      <Header />
      <Row justify='center' className='login-content'>
        <Col xs={0} md={12}>
          <Flex justify='center' align='center' className='login-info-container'>
            <LoginInfo />
          </Flex>
        </Col>
        <Col span={12}>
          <Flex justify='center' align='center' className='login-info-container w-100'>
            <LoginCard />
          </Flex>
        </Col>
      </Row>
      <Footer />
    </Flex>
  )
}
