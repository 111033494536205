import { ArticleSaleUnitSetting } from "../../articles/sale-unit/ArticleSaleUnitSetting"
import { ArticleTagSetting } from "../../articles/tag/ArticleTagSetting"
import { ArticleTaxSetting } from "../../articles/tax/ArticleTaxSetting"
import { ContactTagSetting } from "../../contact/tag/ContactTagSetting"
import { ISettings } from "./ISetting"

export const settings: ISettings[] = [
  // {
  //   title: "Paramètres généraux",
  //   settings: [
  //     { label: "Profil de l'organisation", value: "" },
  //     { label: "Envoie des courriels", value: "" },
  //     { label: "Stockage", value: "" },
  //   ],
  // },
  {
    title: "Contacts",
    settings: [{ label: "Étiquettes", value: "", modalContent: ContactTagSetting }],
  },
  {
    title: "Articles",
    settings: [
      { label: "Unités de vente", value: "", modalContent: ArticleSaleUnitSetting },
      { label: "Taxes", value: "", modalContent: ArticleTaxSetting },
      { label: "Étiquettes", value: "", modalContent: ArticleTagSetting },
    ],
  },
  // {
  //   title: "Devis",
  //   settings: [
  //     { label: "Général", value: "" },
  //     { label: "Condition de vente", value: "" },
  //   ],
  // },
  // {
  //   title: "Factures",
  //   settings: [
  //     { label: "Général", value: "" },
  //     { label: "Moyens de paiement", value: "" },
  //     { label: "Conditions de vente", value: "" },
  //   ],
  // },
  // {
  //   title: "Dépenses",
  //   settings: [
  //     { label: "Général", value: "" },
  //     { label: "Conditions de vente", value: "" },
  //   ],
  // },
]
