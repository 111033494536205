import Paragraph from "antd/es/typography/Paragraph"
import { useEffect, useState } from "react"
import { SearchArticle } from "../../../../types/ISearchArticle"
import { ArticleForm } from "../../../article/components/ArticleForm/ArticleForm"
import NDFSelect from "../../../shared/form/components/select/NDFSelect"
import { useDevisContext } from "../../context/devis.context"
import { IRow } from "../../models/IDevis"

interface Props {
  record: IRow
  onSelect?: (article: SearchArticle) => void
}

const ArticleRow: React.FC<Props> = ({ record, onSelect }) => {
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState("")
  const [showModal, setShowModal] = useState(false)

  const { articles, searchArticles, setArticleDescription } = useDevisContext()

  useEffect(() => {
    setLoading(true)

    const timeout = setTimeout(() => {
      searchArticlesByName(search)
    }, 500)

    return () => {
      clearTimeout(timeout)
      setLoading(false)
    }
  }, [search])

  useEffect(() => {
    setSearch(record.article.artName ?? "")
  }, [record.article.artName])

  const searchArticlesByName = (search: string) => {
    if (search.length && search.length < 3) {
      return
    }
    searchArticles(search).then(() => {
      setSearch(search)
      setLoading(false)
    })
  }

  useEffect(() => {
    console.log("search", search, record.index)
  }, [search])

  return (
    <>
      <NDFSelect<SearchArticle>
        className='article-select'
        onSearch={setSearch}
        search={search}
        onSelect={(article) => {
          if (article && onSelect) {
            onSelect(article)
          }
        }}
        onClick={() => searchArticlesByName(search)}
        options={articles}
        onNew={() => setShowModal(true)}
        isLoading={loading}
      />
      <Paragraph
        hidden={!record.article.artDesc}
        className='article-description'
        editable={{
          triggerType: ["text", "icon"],
          tooltip: "Éditer la description",
          maxLength: 1000,
          autoSize: { minRows: 2 },
          onChange: (desc) => setArticleDescription(desc, record.index),
          enterIcon: null,
        }}
      >
        {record.article.artDesc}
      </Paragraph>

      <ArticleForm
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onSubmit={(article) => {
          setShowModal(false)
          if (article) {
            searchArticlesByName(article.artName)
            onSelect &&
              onSelect(
                new SearchArticle({
                  ...article,
                  IDTaxe: article.taxe.IDTaxe,
                  IDSaleUnit: article.saleUnit.IDSaleUnit,
                  taxeCoeff: article.taxe.taxeCoeff,
                  taxeName: article.taxe.taxeName,
                  suName: article.saleUnit.suName,
                  suDisplayName: article.saleUnit.suDisplayName,
                }),
              )
          }
        }}
      />
    </>
  )
}

export default ArticleRow
