import { EContent } from "../types/EContent"
import { Article, IArticle, IArticleFromAPI } from "../types/IArticle"
import { BasicArticle, IBasicArticleFromAPI } from "../types/IBasicArticle"
import { ISaleUnit } from "../types/ISaleUnit"
import { ISearchArticle, SearchArticle } from "../types/ISearchArticle"
import { ITaxe } from "../types/ITaxe"

import { NDFFetcher } from "./Fetcher"
import { UtilAPI } from "./Utils.api"

const BASE_URI = "/articles"

export class ArticleAPI {
  static getArticles = async () => {
    return NDFFetcher.get<IBasicArticleFromAPI[]>(`${BASE_URI}/articles`).then((res) => {
      const articles = Object.values(res.data).map((contact) => {
        return new BasicArticle(contact)
      })

      return {
        ...res,
        data: articles,
      }
    })
  }

  static searchArticles = async (search: string) => {
    return NDFFetcher.get<ISearchArticle[]>(`${BASE_URI}/search`, {
      by: "name",
      q: search,
    }).then((res) => {
      const articles = Object.values(res.data).map((contact) => {
        return new SearchArticle(contact)
      })

      return {
        ...res,
        data: articles,
      }
    })
  }

  static getArticle = async (id: number) => {
    return NDFFetcher.get<IArticleFromAPI>(`${BASE_URI}/articles`, { id }).then((res) => ({
      ...res,
      data: new Article(res.data),
    }))
  }

  static update = async (contact: any) => {
    return NDFFetcher.put<IArticle>(`${BASE_URI}/articles`, contact)
  }

  static delete = async (id: number) => {
    return NDFFetcher.delete<IArticle>(`${BASE_URI}/articles`, { id })
  }

  static create = async (contact: any) => {
    return NDFFetcher.post<IArticle>(`${BASE_URI}/articles`, contact)
  }

  static getArticlesPageContent = () => {
    return UtilAPI.getPageContent(EContent.ARTICLES)
  }

  static getSaleUnits = () => {
    return NDFFetcher.get<ISaleUnit[]>(`${BASE_URI}/salesunits`)
  }

  static createSaleUnit = async (unit: Omit<ISaleUnit, "IDSaleUnit">) => {
    return NDFFetcher.post<ISaleUnit>(`${BASE_URI}/salesunits`, unit)
  }

  static updateSaleUnit = async (unit: ISaleUnit) => {
    return NDFFetcher.put(`${BASE_URI}/salesunits`, unit)
  }

  static deleteSaleUnit = async (id: number) => {
    return NDFFetcher.delete(`${BASE_URI}/salesunits`, { id })
  }

  static getTaxes = () => {
    return NDFFetcher.get<ITaxe[]>(`${BASE_URI}/taxes`)
  }

  static createTax = async (tax: Omit<ITaxe, "IDTaxe">) => {
    return NDFFetcher.post<ITaxe>(`${BASE_URI}/taxes`, tax)
  }

  static updateTax = async (tax: ITaxe) => {
    return NDFFetcher.put(`${BASE_URI}/taxes`, tax)
  }

  static deleteTax = async (id: number) => {
    return NDFFetcher.delete(`${BASE_URI}/taxes`, { id })
  }
}
