import { EContent } from '../types/EContent'
import { IAddress } from '../types/IAddress'
import { IAddresses } from '../types/IAddresses'
import { ICountry } from '../types/ICountry'
import { IPageInfo } from '../types/IPageInfo'
import { ITableColumns } from '../types/ITableColumn'
import { APIFetcher } from './Fetcher'

const API_KEY = process.env.REACT_APP_GEOCODE_API_KEY ?? ''

export class UtilAPI {
  static getPageContent = (slug: EContent) => {
    return APIFetcher.get<IPageInfo>('/utilities/load_page_content', { slug })
  }

  static getTableColumns = async (slug: EContent, userId: number) => {
    return APIFetcher.get<[ITableColumns]>('/utilities/columns', { slug, userId }).then((res) => ({
      ...res,
      data: res.data.at(0),
    }))
  }

  static saveTableColumns = (slug: EContent, userId: number, columnIds: string[]) => {
    const values = {}
    columnIds?.map((columnId, index) => {
      // @ts-expect-error stop
      values[`column[${columnId}][IDColumn]`] = columnId
      // @ts-expect-error stop
      values[`column[${columnId}][displayOrder]`] = index + 1
    })
    return APIFetcher.post<ITableColumns>(
      `/utilities/columns?slug=${slug}&userId=${userId}`,
      values,
    )
  }

  static getCountries = () => {
    return APIFetcher.get<ICountry[]>('/datas/countries')
  }

  // DOC: https://www.here.com/docs/bundle/geocoding-and-search-api-v7-api-reference/page/index.html#/paths/~1geocode/get
  static getCoordinatesFromAddress = (address: string): Promise<IAddress[]> => {
    if (address.length < 3) {
      return Promise.resolve([])
    }
    const urlSearch = new URLSearchParams({
      apiKey: API_KEY,
      q: address,
      limit: '10',
      lang: 'fr',
    })
    return fetch(`https://geocode.search.hereapi.com/v1/geocode?${urlSearch}`).then((res) =>
      res.json().then((res: IAddresses) => res.items ?? []),
    )
  }
}
