import { create } from "zustand"
import { persist } from "zustand/middleware"
import { IUser } from "../types/IUser"

interface UserState {
  user: IUser
  create: (user: IUser) => void
  update: (newUser: IUser) => void
  logout: () => void
}

export const useUserStore = create<UserState>()(
  persist(
    (set) => ({
      user: {
        IDUser: 0,
        IDClient: 0,
        IDSelectedEnt: 0,
        userLastName: "",
        userFirstName: "",
        userEmail: "",
        userAvatar: "",
        userFonction: "",
        token: "",
      },
      create: (user) =>
        set(() => {
          // TODO: Remove this condition when the API is ready
          process.env.REACT_APP_ENV !== "DEV" && storeToken(user.token)
          return { user: { ...user, isAuth: true } }
        }),
      update: (newUser) => set((state) => ({ user: { ...state.user, ...newUser } })),
      logout: () => set({ user: undefined }),
    }),
    { name: "NeelapsDevisFacturationStore" },
  ),
)

export const useUser = () => {
  return useUserStore((state) => state.user)
}

export const getToken = () => {
  return localStorage.getItem("token")
}

export const storeToken = (token: string) => {
  return localStorage.setItem("token", token)
}

export const deleteToken = () => {
  return localStorage.removeItem("token")
}
