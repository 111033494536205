/* eslint-disable camelcase */
import dayjs from "dayjs"
import DevisUtils from "../utils/devis.utils"
import { EDevisStatus } from "./EDevisStatus"
import { IDevis, IRow } from "./IDevis"
import IDevisContact from "./IDevisContact"

export default interface ICreateUpdateDevis {
  IDDevis?: number
  devisStatut: EDevisStatus

  datePiece: string
  dateExpiration: string

  devisTitle: string

  devisTotalDevis: number
  devisTotalRemises: number
  devisTotalHT: number
  devisTotalTTC: number

  devisCGV: string

  IDTiers: number
  tiers_displayName: string
  tiers_formattedAddress: string
  tiers_adresse: string
  tiers_codePostal: number
  tiers_ville: string
  tiers_countryCode: string
  tiers_countryName: string

  _lignes?: object
}

export interface ICreateUpdateDevisRow {
  IDDevisLigne: number
  IDArt: number
  IDTaxe: number
  IDSaleUnit: number
  lineNum: number
  lineType: "art" | "title"
  artRef: string
  artType: "marchandise" | "service"
  artName: string
  artDesc: string
  artPVHT: number
  artQuant: number
  remiseValeur: number
  remiseType: "P" | "V"
  remiseMontant: number
  taxeAmount: number
  totalLigne: number
}

export interface IDevisForm
  extends Omit<
    IDevis,
    "datePiece" | "dateExpiration" | "devisTotalHT" | "devisTotalTaxes" | "devisTotalTTC"
  > {
  datePiece: Date
  dateExpiration: Date
}

export class CreateUpdateDevis implements ICreateUpdateDevis {
  IDDevis?: number | undefined
  devisStatut: EDevisStatus
  datePiece: string
  dateExpiration: string
  devisTotalDevis: number
  devisTotalRemises: number
  devisTitle: string
  devisTotalHT: number
  devisTotalTTC: number
  devisCGV: string
  IDTiers: number
  tiers_displayName: string
  tiers_formattedAddress: string
  tiers_adresse: string
  tiers_codePostal: number
  tiers_ville: string
  tiers_countryCode: string
  tiers_countryName: string
  _lignes: any

  constructor(devis: IDevisForm, tiers: IDevisContact, lines: IRow[]) {
    this.IDDevis = devis.IDDevis
    this.devisStatut = devis.devisStatut
    this.datePiece = dayjs(devis.datePiece).format("YYYY-MM-DD")
    this.dateExpiration = dayjs(devis.dateExpiration).format("YYYY-MM-DD")
    this.devisTitle = devis.devisTitle

    this.devisTotalDevis = lines
      .filter((line) => !line.isTitle)
      .reduce((acc, line) => {
        return acc + line.price * line.quantity
      }, 0)
    this.devisTotalRemises = lines
      .filter((line) => !line.isTitle)
      .reduce((acc, line) => {
        return acc + line.discount.calculateDiscount(line.price * line.quantity)
      }, 0)
    this.devisTotalHT = DevisUtils.computeSubTotal(lines)
    this.devisTotalTTC = DevisUtils.computeTotal(lines)

    this.devisCGV = devis.devisCGV
    // TODO: missing "info client" field

    this.IDTiers = tiers.IDTiers
    this.tiers_displayName = tiers.displayName
    this.tiers_formattedAddress = tiers.formattedAddress // TODO: Toujours à null
    this.tiers_adresse = "" // TODO: Je n'ai pas cette information
    this.tiers_codePostal = tiers.codePostal
    this.tiers_ville = tiers.ville
    this.tiers_countryCode = "" // TODO: same
    this.tiers_countryName = "" // TODO: same

    this._lignes = {}
    lines.map((line, index) => {
      this._lignes[`line[${index}][IDDevisLigne]`] = line.IDDevisLigne

      this._lignes[`line[${index}][IDArt]`] = line.article?.IDArt
      this._lignes[`line[${index}][IDTaxe]`] = line.tax?.IDTaxe
      this._lignes[`line[${index}][IDSaleUnit]`] = line.unit?.IDSaleUnit
      this._lignes[`line[${index}][artRef]`] = line.article?.artRef
      this._lignes[`line[${index}][artType]`] = line.article?.artType
      this._lignes[`line[${index}][artName]`] = line.article?.artName
      this._lignes[`line[${index}][artDesc]`] = line.article?.artDesc
      this._lignes[`line[${index}][artPVHT]`] = line.article?.artPVHT
      this._lignes[`line[${index}][artQuant]`] = line.quantity
      this._lignes[`line[${index}][remiseValeur]`] = line.discount?.value
      this._lignes[`line[${index}][remiseType]`] = line.discount?.getTypeLabel()
      this._lignes[`line[${index}][remiseMontant]`] = line.discount?.calculateDiscount(
        line.price * line.quantity,
      )
      this._lignes[`line[${index}][taxeAmount]`] = line.tax?.taxeCoeff // TODO: C'est quoi ?

      this._lignes[`line[${index}][totalLigne]`] = line.isTitle
        ? 0
        : line.price * line.quantity -
          line.discount.calculateDiscount(line.price * line.quantity) * (line.tax.taxeCoeff / 100)

      this._lignes[`line[${index}][lineNum]`] = line.index
      this._lignes[`line[${index}][lineType]`] = line.isTitle ? "title" : "art"
    })
  }
}
