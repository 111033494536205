import { EyeInvisibleOutlined, EyeTwoTone, UserOutlined } from '@ant-design/icons'
import { Button, Card, Form, Input, notification } from 'antd'
import { useNavigate } from 'react-router-dom'
import { LoginAPI } from '../../../../services/Login.api'
import { useUserStore } from '../../../../stores/User.store'
import './loginCard.scss'

export const LoginCard: React.FC = () => {
  const userStore = useUserStore()
  const navigator = useNavigate()

  const onLogin = ({ login, password }: { login: string; password: string }) => {
    LoginAPI.login(login, password)
      .then((response) => {
        if (response.ok && !Array.isArray(response.data.user)) {
          userStore.create(response.data.user)
          navigator('/auth/dashboard/', { replace: true })
        } else {
          notification.error({
            message: 'Erreur connexion',
            description:
              'Impossible de se connecter, vérifiez votre nom d\'utilisateur et votre mot de passe.',
          })
        }
      })
      .catch(() => {
        notification.error({
          message: 'Erreur connexion',
          description:
            'Impossible de se connecter, vérifiez votre nom d\'utilisateur et votre mot de passe.',
        })
      })
  }

  return (
    <Card className='login-card'>
      <section className='title'>SE CONNECTER À VOTRE COMPTE</section>
      <Form className='login-form' onFinish={onLogin}>
        <Form.Item
          className='username'
          name='login'
          rules={[
            {
              required: true,
              message: 'Insérer votre nom d\'utilisateur',
            },
          ]}
          initialValue={process.env.REACT_APP_ENV === 'DEV' ? process.env.REACT_APP_DEV_LOGIN : ''}
        >
          <Input
            size='large'
            placeholder="Nom d'utilisateur"
            autoComplete='username'
            prefix={<UserOutlined />}
          />
        </Form.Item>
        <Form.Item
          name='password'
          rules={[
            {
              required: true,
              message: 'Insérer votre mot de passe',
            },
          ]}
          initialValue={
            process.env.REACT_APP_ENV === 'DEV' ? process.env.REACT_APP_DEV_PASSWORD : ''
          }
        >
          <Input.Password
            size='large'
            placeholder='Mot de passe'
            autoComplete='current-password'
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>
        <Button type='primary' htmlType='submit'>
          Se connecter
        </Button>
      </Form>
    </Card>
  )
}
