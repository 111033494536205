import { Button } from "antd"
import { PlusSmall } from "react-flaticons"
import "./buttonNew.scss"

interface Props {
  onClick: () => void
  size?: "small" | "middle" | "large"
  withoutIcon?: boolean
}

export const ButtonNew = ({ onClick, size = "middle", withoutIcon }: Props) => {
  return (
    <Button
      className='button-new'
      type='primary'
      icon={withoutIcon ? null : <PlusSmall />}
      onClick={onClick}
      size={size}
    >
      Nouveau
    </Button>
  )
}
