import { Form, Input } from "antd"
import { AddressPicker } from "../items/AddressPicker"

export const AddressWithInfos = () => {
  return (
    <>
      <Form.Item label='Complément' name='complement'>
        <Input placeholder='Complément' />
      </Form.Item>
      <Form.Item label='Adresse'>
        <AddressPicker />
      </Form.Item>
      <Form.Item name='email' label='Adresse Mail'>
        <Input placeholder='Adresse Mail' />
      </Form.Item>
      <Form.Item label='Téléphone' name='phone'>
        <Input placeholder='Téléphone' type='tel' />
      </Form.Item>
    </>
  )
}
