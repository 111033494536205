import { Image, notification, Skeleton } from 'antd'
import { useEffect, useState } from 'react'
import { LoginAPI } from '../../../../services/Login.api'
import { IPageInfo } from '../../../../types/IPageInfo'
import './loginInfo.scss'

export const LoginInfo: React.FC = () => {
  const [content, setContent] = useState<IPageInfo>()
  const [isLoading, setIsLoading] = useState(true)

  const [api, contextHolder] = notification.useNotification()

  const openNotificationWithIcon = (type: 'error' | 'success', title: string, content: string) => {
    api[type]({
      message: title,
      description: content,
    })
  }

  useEffect(() => {
    LoginAPI.getLoginPageContent()
      .then((result) => {
        setContent(result.data)
        setTimeout(() => {
          setIsLoading(false)
        }, 500)
      })
      .catch(() => {
        openNotificationWithIcon('error', 'Erreur connexion', 'Impossible de récupérer le contenu')
      })
  }, [])

  return (
    <div className='login-info'>
      {contextHolder}
      <Skeleton loading={isLoading} paragraph={{ rows: 2 }}>
        <div>
          <h1 className='title'>{content?.titre ?? 'Neelaps Devis Facturation'}</h1>
          <p className='mini-title'>{content?.ssTitre}</p>
        </div>
        <p className='description'>
          {content?.texte ??
            'Neelaps est une application web qui vous permet de gérer vos devis et factures de manière simple et efficace.'}
        </p>
      </Skeleton>
      <div>
        {isLoading ? (
          <Skeleton.Image active className='img-loader' />
        ) : (
          <Image
            width={'100%'}
            fallback='/assets/about_app.png'
            src={content?.imgSrc}
            alt='Neelaps preview'
            preview={false}
          />
        )}
      </div>
    </div>
  )
}
