import { LoadingOutlined } from "@ant-design/icons"
import { Col, Form, Input, notification, Row, Select, Spin } from "antd"
import { useEffect, useState } from "react"
import { Marker } from "react-flaticons"
import { UtilAPI } from "../../../../../services/Utils.api"
import { IAddress } from "../../../../../types/IAddress"
import { ICountry } from "../../../../../types/ICountry"

export const AddressPicker: React.FC = () => {
  const [adresses, setAddresses] = useState<IAddress[]>([])
  const [countries, setCountries] = useState<ICountry[]>([])
  const [loadingAddresses, setLoadingAddresses] = useState(false)
  const [search, setSearch] = useState("")
  const form = Form.useFormInstance()

  const DEBOUNCE_DURATION = 700

  useEffect(() => {
    UtilAPI.getCountries()
      .then((res) => {
        if (res.ok) {
          setCountries(res.data)
        } else {
          notification.error({
            message: "Erreur",
            description: "Impossible de récupérer la liste des pays.",
          })
        }
      })
      .catch((err) => {
        notification.error({
          message: "Erreur",
          description: `Impossible de récupérer la liste des pays. Erreur: ${JSON.stringify(err)}`,
        })
      })
  }, [])

  useEffect(() => {
    setLoadingAddresses(true)

    const getData = setTimeout(() => {
      searchForAddresses(search)
    }, DEBOUNCE_DURATION)

    return () => clearTimeout(getData)
  }, [search])

  const searchForAddresses = (search: string) => {
    if (!search) {
      setAddresses([])
      return
    }
    UtilAPI.getCoordinatesFromAddress(search).then((res) => {
      if (res?.length > 0) {
        setAddresses(res)
      }
      setLoadingAddresses(false)
    })
  }

  const onAddressSelect = (placeId: string) => {
    const selectedAddress = adresses.find((a) => a.id === placeId)
    if (selectedAddress) {
      form.setFieldsValue({
        formattedAddress: selectedAddress.title,
        address: `${selectedAddress.address.houseNumber} ${selectedAddress.address.street}`,
        codePostal: selectedAddress.address.postalCode,
        ville: selectedAddress.address.city,
        countryName: selectedAddress.address.countryName,
        countryCode: selectedAddress.address.countryCode,
      })
    }
  }

  return (
    <>
      <Form.Item name='address'>
        <Select
          placeholder='Adresse'
          notFoundContent={null}
          suffixIcon={<Marker />}
          showSearch
          onSearch={(value) => setSearch(value)}
          filterOption={(inputValue, option) => {
            if (option?.label) {
              return option.label.toString().toLowerCase().includes(inputValue.toLowerCase())
            }
            return false
          }}
          options={adresses.map((address) => ({
            value: address.id,
            label: address.title,
          }))}
          allowClear={{
            clearIcon: loadingAddresses ? (
              <Spin indicator={<LoadingOutlined style={{ marginTop: -2, fontSize: 15 }} spin />} />
            ) : undefined,
          }}
          onSelect={(address) => onAddressSelect(address)}
        />
      </Form.Item>
      <Row gutter={5}>
        <Col span={8}>
          <Form.Item name='codePostal'>
            <Input allowClear placeholder='Code Postal' />
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item name='ville'>
            <Input allowClear placeholder='Ville' />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name='countryName' className='country-picker'>
        <Select
          placeholder='Pays'
          notFoundContent={null}
          showSearch
          filterOption={(inputValue, option) => {
            if (option?.label) {
              return option.label.toString().toLowerCase().includes(inputValue.toLowerCase())
            }
            return false
          }}
          options={
            countries
              ? countries?.map((address) => ({
                  value: address.countryCode,
                  label: address.countryName,
                }))
              : []
          }
          allowClear
          onSelect={(countryCode) =>
            form.setFieldsValue({
              countryCode,
            })
          }
        />
      </Form.Item>

      {/* This is used to add hidden values into the form */}
      <Form.Item noStyle name='formattedAddress'>
        <div />
      </Form.Item>
      <Form.Item noStyle name='countryCode'>
        <div />
      </Form.Item>
    </>
  )
}
