import { Highlight } from "../../../../shared/utils/components/Highlight/Highlight"
import { ISettings } from "../../models/ISetting"
import { Setting } from "../setting/Setting"
import "./settingGroup.scss"

interface Props {
  group: ISettings
  highlightWord: string | undefined
}

export const SettingGroup: React.FC<Props> = ({ group, highlightWord }) => {
  const renderSettings = () => {
    return group.settings.map((setting, i) => (
      <Setting key={i} setting={setting} highlightWord={highlightWord} />
    ))
  }

  return (
    <div className='setting-group'>
      <h4>
        <Highlight textToHighlight={group.title} search={highlightWord || ""} />
      </h4>
      <ul>{renderSettings()}</ul>
    </div>
  )
}
