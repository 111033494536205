import { ESuiviObject } from '../types/ESuiviObject'
import { ESuiviTypes } from '../types/ESuiviTypes'
import { ISuivi } from '../types/ISuivi'
import { NDFFetcher } from './Fetcher'

export class SuiviAPI {
  static async getSuivi(type: ESuiviTypes, objectName: ESuiviObject, objectId: number) {
    return NDFFetcher.get<ISuivi[]>('/suivi', { type, objectName, objectId })
  }

  static async createComment(
    type: ESuiviTypes,
    objectName: ESuiviObject,
    objectId: number,
    comment: string,
  ) {
    return NDFFetcher.post('/suivi', { type, objectName, objectId, comment })
  }

  static async updateComment(id: number, text: string) {
    return NDFFetcher.put('/suivi', { IDSuivi: id, eventContent: text })
  }

  static async deleteComment(id: number) {
    return NDFFetcher.delete('/suivi', { id })
  }
}
