import { Avatar, Button, Col, Flex, notification, Row, Typography } from 'antd'
import { useContext, useState } from 'react'
import { SuiviAPI } from '../../../../../../services/Suivi.api'
import { useUser } from '../../../../../../stores/User.store'
import { ISuivi } from '../../../../../../types/ISuivi'
import { ActivityContext } from '../FormActivity/FormActivity'
import { FormActivityInput } from '../FormActivityInput/FormActivityInput'
import './formActivityEntry.scss'

const { Text } = Typography

interface IFormHistoryProps {
  entry: ISuivi
  readonly: boolean
  submit: (comment: string) => void
}

export const FormActivityEntry: React.FC<IFormHistoryProps> = ({ entry, readonly }) => {
  const [edit, setEdit] = useState(false)
  const activity = useContext(ActivityContext)
  const user = useUser()

  const deleteComment = (id: number) => {
    SuiviAPI.deleteComment(id)
      .then(() => {
        activity.loadData()
      })
      .catch(() => {
        notification.error({
          message: 'Erreur',
          description: 'Impossible de supprimer le commentaire',
        })
      })
  }

  const updateComment = (text: string) => {
    setEdit(false)
    SuiviAPI.updateComment(entry.IDSuivi, text)
      .then(() => {
        activity.loadData()
      })
      .catch(() => {
        notification.error({
          message: 'Erreur',
          description: 'Impossible de modifié le commentaire',
        })
      })
  }

  return (
    <div className='form-activity-entry'>
      <Col>
        <Row>
          <Avatar size={50} src={entry.avatar} />
          <Col className='entry'>
            {edit ? (
              <FormActivityInput
                submit={updateComment}
                comment={entry.eventContent}
                cancel={() => setEdit(false)}
              />
            ) : (
              <div className='activity-content'>
                <Row className='header'>
                  <Flex>
                    <div>
                      <Text strong>{entry.creatorName} </Text>
                      {entry.eventTitle}
                    </div>
                  </Flex>
                  <Flex>
                    <div>
                      {entry.isUpdated && 'Modifié'} {entry.eventDate}
                    </div>
                  </Flex>
                </Row>
                <div
                  className='entry'
                  dangerouslySetInnerHTML={{ __html: entry.eventContent }}
                ></div>
                {!readonly && entry.isDeletable > 0 && entry.creatorId === user.IDUser && (
                  <Flex className='comment-actions'>
                    <Button
                      type='link'
                      size='small'
                      onClick={() => {
                        setEdit(!edit)
                      }}
                    >
                      <Text className='edit' strong>
                        Modifier
                      </Text>
                    </Button>
                    <Text className='separator'>•</Text>
                    <Button type='link' size='small' onClick={() => deleteComment(entry.IDSuivi)}>
                      <Text className='delete' strong>
                        Supprimer
                      </Text>
                    </Button>
                  </Flex>
                )}
              </div>
            )}
          </Col>
        </Row>
      </Col>
    </div>
  )
}
