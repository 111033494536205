import { notification } from "antd"
import { FC, useEffect, useState } from "react"
import { UsersAlt } from "react-flaticons"
import { ContactAPI } from "../../../../services/Contacts.api"
import { useContactStore } from "../../../../stores/Contact.store"
import { EContent } from "../../../../types/EContent"
import { IBasicContact } from "../../../../types/IBasicContact"
import { IPageInfo } from "../../../../types/IPageInfo"
import { TablePage } from "../../../shared/form/pages/TablePage/TablePage"

interface Props {
  onNewContact: () => void
  onEditContact: (contactId: number) => void
  refresh: boolean
}

type Contact = IBasicContact & { key: number }

export const ContactTable: FC<Props> = ({ onNewContact, onEditContact, refresh }) => {
  const [content, setContent] = useState<IPageInfo>()
  const [loading, setLoading] = useState(true)
  const [contacts, setContacts] = useState<Contact[]>([])

  const contactStore = useContactStore()

  useEffect(() => {
    setLoading(true)
    ContactAPI.getContactPageContent()
      .then((result) => {
        setContent(result.data)
        setTimeout(() => {
          setLoading(false)
        }, 500)
      })
      .catch(() => {
        notification.error({
          message: "Erreur",
          description: "Impossible de récupérer la description des contacts",
        })
      })
  }, [])

  useEffect(() => {
    ContactAPI.getContacts()
      .then((res) => {
        if (res.ok) {
          contactStore.set(res.data)
          setContacts(
            res.data.map((contact) => ({
              ...contact,
              key: contact.IDTiers,
            })),
          )
        }
      })
      .catch(() => {
        notification.error({
          message: "Erreur",
          description: "Impossible de récupérer la liste des contacts",
        })
      })
  }, [refresh])

  return (
    <TablePage
      loading={loading}
      tableType={EContent.CONTACTS}
      data={contacts}
      icon={<UsersAlt />}
      onNew={onNewContact}
      onNewText={content?.btnTxt ?? "Nouveau contact"}
      title='Contacts'
      pageObjectives=''
      pageDescription={content?.ssTitre ?? ""}
      whatCanBeDone={content?.texte.split("-") ?? []}
      onEdit={(contact: IBasicContact) => onEditContact(contact.IDTiers)}
      filters={{
        companyType: {
          filters: [
            {
              text: "Particulier",
              value: "Particulier",
            },
            {
              text: "Entreprise",
              value: "Entreprise",
            },
          ],
          onFilter: (value: string, record: Contact) =>
            record.companyType.includes(value as string),
        },
      }}
    />
  )
}
