import { Form, Input, Modal, notification, Spin } from "antd"
import { useForm } from "antd/es/form/Form"
import { useEffect, useState } from "react"
import { ContactAPI } from "../../../../../services/Contacts.api"
import { AddressWithInfos } from "./AddressWithInfos"

interface Props {
  addressId?: number
  idContact?: number
  open: boolean
  onCancel: () => void
  onOk: () => void
}

export const AddressModal: React.FC<Props> = ({ addressId, onCancel, onOk, open, idContact }) => {
  const [loading, setLoading] = useState(false)
  const [isPrimaryAddress, setIsPrimaryAddress] = useState(false)
  const [form] = useForm()

  useEffect(() => {
    if (addressId) {
      setLoading(true)
      ContactAPI.getAddress(addressId).then((res) => {
        form.setFieldsValue(res.data)
        setIsPrimaryAddress(res.data.pal !== -1)
        setLoading(false)
      })
    }
  }, [addressId, open])

  const submit = () => {
    const data = form.getFieldsValue()
    if (Object.entries(data).every(([, value]) => value === undefined)) {
      notification.error({
        message: "Erreur",
        description: "L'adresse ne peut pas être vide",
      })
      return
    }

    if (addressId) {
      ContactAPI.updateAddress({
        IDAddress: addressId,
        IDTiers: idContact,
        ...data,
      }).then(() => {
        onOk()
      })
    } else {
      ContactAPI.createAddress({
        IDTiers: idContact,
        ...data,
      }).then(() => {
        onOk()
      })
    }
  }

  return (
    <Modal
      title={addressId ? "Mise à jour de l'adresse" : "Nouvelle adresse"}
      open={open}
      onCancel={() => {
        onCancel()
        setTimeout(() => {
          form.resetFields()
        }, 500)
      }}
      onOk={() => {
        if (!loading) {
          submit()
          setTimeout(() => {
            form.resetFields()
          }, 500)
        }
      }}
    >
      <Spin spinning={loading}>
        <Form form={form} labelCol={{ span: 7 }} labelWrap labelAlign='left' layout='horizontal'>
          <Form.Item label='Nom' name='addressName'>
            <Input placeholder='Nom' disabled={isPrimaryAddress} />
          </Form.Item>
          <AddressWithInfos />
        </Form>
      </Spin>
    </Modal>
  )
}
