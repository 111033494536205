import { NDFFetcher } from "../../../services/Fetcher"
import { UtilAPI } from "../../../services/Utils.api"
import { EContent } from "../../../types/EContent"
import { IResponse } from "../../../types/IResponse"
import { EDevisStatus } from "../models/EDevisStatus"
import { IBasicDevis, IBasicDevisFromAPI } from "../models/IBasicDevis"
import ICreateUpdateDevis from "../models/ICreateUpdateDevis"
import { IDevisNumbering } from "../models/IDevisNumbering"

const BASE_URI = "/ventes/devis"

export class DevisAPI {
  static getPageContent = async () => {
    return UtilAPI.getPageContent(EContent.DEVIS)
  }

  static getAllDevis = async (): Promise<IResponse<IBasicDevis[]>> => {
    return NDFFetcher.get<IBasicDevisFromAPI[]>(BASE_URI).then((res) => {
      return {
        ...res,
        data: Object.values(res.data).map(
          (devis) =>
            ({
              ...devis,
              devisStatut: new EDevisStatus(devis.devisStatut),
            }) as IBasicDevis,
        ),
      }
    })
  }

  static createDevis = (devis: ICreateUpdateDevis) => {
    const lines = devis._lignes
    delete devis._lignes
    return NDFFetcher.post(BASE_URI, { ...devis, ...lines })
  }

  static createNumbering = (prefix: string, pattern: string, startNumber: number) => {
    return NDFFetcher.put("/settings", {
      action: "updNumerotation",
      type: "devis",
      prefixDV: prefix,
      // eslint-disable-next-line camelcase
      typeNum_DV: pattern,
      // eslint-disable-next-line camelcase
      numAuto_DV: startNumber,
    })
  }

  static getDevisNumbering = () => {
    return NDFFetcher.get<IDevisNumbering>("/settings?action=getNumerotation&type=devis")
  }
}
