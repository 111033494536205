import { notification, Statistic } from "antd"
import { FC, useEffect, useState } from "react"
import { Box } from "react-flaticons"
import { ArticleAPI } from "../../../../services/Article.api"
import { useArticleStore } from "../../../../stores/Article.store"
import { EContent } from "../../../../types/EContent"
import { IBasicArticle } from "../../../../types/IBasicArticle"
import { IPageInfo } from "../../../../types/IPageInfo"
import { TablePage } from "../../../shared/form/pages/TablePage/TablePage"
import "./articleTable.scss"

interface Props {
  onNew: () => void
  onEdit: (id: number) => void
  refresh: boolean
}

export const ArticleTable: FC<Props> = ({ onEdit, onNew, refresh }) => {
  const [content, setContent] = useState<IPageInfo>()
  const [loading, setLoading] = useState(true)
  const [articles, setArticles] = useState<IBasicArticle[]>([])

  const articlesStore = useArticleStore()

  useEffect(() => {
    setLoading(true)
    ArticleAPI.getArticlesPageContent()
      .then((result) => {
        setContent(result.data)
        setTimeout(() => {
          setLoading(false)
        }, 500)
      })
      .catch(() => {
        notification.error({
          message: "Erreur",
          description: "Impossible de récupérer la description des articles",
        })
      })
  }, [])

  useEffect(() => {
    ArticleAPI.getArticles()
      .then((res) => {
        if (res.ok) {
          articlesStore.set(res.data)
          setArticles(
            res.data.map((article) => ({
              ...article,
              key: article.IDArt,
            })),
          )
        }
      })
      .catch(() => {
        notification.error({
          message: "Erreur",
          description: "Impossible de récupérer la liste des articles",
        })
      })
  }, [refresh])

  const columnRules = {
    artPVHT: {
      align: "end",
      render: (price: number) =>
        price && <Statistic value={price} precision={2} groupSeparator=' ' suffix='€' />,
    },
  }

  return (
    <TablePage
      loading={loading}
      tableType={EContent.ARTICLES}
      data={articles}
      icon={<Box />}
      onNew={onNew}
      onNewText={content?.btnTxt ?? "Nouveau article"}
      title='Articles'
      pageObjectives=''
      pageDescription={content?.ssTitre ?? ""}
      whatCanBeDone={content?.texte.split("-") ?? []}
      onEdit={(article: IBasicArticle) => onEdit(article.IDArt)}
      columnRules={columnRules}
    />
  )
}
