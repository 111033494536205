import { SearchOutlined } from "@ant-design/icons"
import { Button, Input, Space, TableColumnType } from "antd"
import { FilterDropdownProps } from "antd/es/table/interface"
import { Highlight } from "../../../utils/components/Highlight/Highlight"
import "./TableFilter.scss"

interface Props {
  colTitle: string
  columnName: string
  hideFilter?: boolean
  render?: (value: any) => JSX.Element | string
}

export const tableFilter = ({ columnName, hideFilter, render, colTitle }: Props) => {
  let searchText = ""
  let searchedColumnName = ""

  /**
   * Handles the search in the table
   * @param search
   * @param confirm
   * @param colName when empty string ("") the search for the column is cleared
   */
  const handleSearch = (
    search: string[],
    confirm: FilterDropdownProps["confirm"],
    colName?: string,
  ) => {
    confirm()
    searchText = search[0]
    searchedColumnName = colName ?? columnName
  }

  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    searchText = ""
  }

  const renderLine = (value: string) => {
    if (searchedColumnName === columnName) {
      return <Highlight textToHighlight={value ? String(value) : ""} search={searchText} />
    }

    if (render !== undefined) {
      return render(value)
    }

    return value
  }


  if (hideFilter) {
    return {
      render: renderLine,
    } as TableColumnType<string>
  } else {
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className='filter-dropdown' onKeyDown={(e) => e.stopPropagation()}>
          <Input
            id={`table-search-${columnName}`}
            placeholder={`Rechercher ${colTitle}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys as string[], confirm)}
          />
          <Space className='buttons'>
            <Button
              type='primary'
              onClick={() => handleSearch(selectedKeys as string[], confirm)}
              icon={<SearchOutlined />}
              size='small'
              className='btn-search'
            >
              Rechercher
            </Button>
            <Button
              onClick={() => {
                searchText = ""
                clearFilters && handleReset(clearFilters)
                handleSearch(selectedKeys as string[], confirm, "")
              }}
              size='small'
              className='btn-reset'
            >
              Effacer
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "" : undefined }} />
      ),
      onFilter: (value, record: any) =>
        String(record[columnName])
          .toLowerCase()
          .includes((value as string).toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => document.getElementById(`table-search-${columnName}`)?.focus(), 100)
        }
      },
      render: renderLine,
    } as TableColumnType<string>
  }
}
