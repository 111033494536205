import { Link } from 'react-router-dom'
import './footer.scss'

export const Footer: React.FC = () => {
  return (
    <div className='footer'>
      <p>
        © {new Date().getFullYear().toString()} Neelaps - Logiciels de gestion d{'\''}entreprise by{' '}
        <Link to={'https://www.elapida.fr/'} target='_blank'>
          Elapida
        </Link>
      </p>
    </div>
  )
}
