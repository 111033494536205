import { HolderOutlined } from "@ant-design/icons"
import { DndContext, DragEndEvent } from "@dnd-kit/core"
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities"
import { restrictToVerticalAxis } from "@dnd-kit/modifiers"
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { Button, Dropdown, Flex, Input, InputNumber, Select, Table, Typography } from "antd"
import React, { useContext, useMemo } from "react"
import { Discount, DiscountType, IRow } from "../../models/IDevis"
import ArticleRow from "./ArticleRow"

import { ItemType } from "antd/es/menu/hooks/useItems"
import { MenuDotsVertical, X } from "react-flaticons"
import { cloneRow, useDevisContext } from "../../context/devis.context"
import "./table.scss"

type EditableTableProps = Parameters<typeof Table>[0]

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>

type ColumnType = Omit<ColumnTypes[number], "render"> & {
  editable?: boolean
  dataIndex: string
  render?: (text: unknown, record: IRow) => React.ReactNode
}

export const NDFTable: React.FC = () => {
  const {
    lines,
    units,
    taxes,
    setLines,
    setArticle,
    setDiscount,
    setPrice,
    setQuantity,
    setUnit,
    setTax,
    setLineTitle,
  } = useDevisContext()

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = lines.findIndex((record) => record.key === active?.id)
      const overIndex = lines.findIndex((record) => record.key === over?.id)
      setLines(arrayMove(lines, activeIndex, overIndex), true)
    }
  }

  const defaultColumns: ColumnType[] = [
    {
      key: "sort",
      align: "center",
      width: 10,
      className: "drag-handler",
      render: () => <DragHandle disabled={lines.length < 2} />,
      dataIndex: "sort",
      title: " ", // Empty title else antd will display a weird div
    },
    {
      title: "Détail de l'article",
      dataIndex: "description",
      align: "left",
      className: "article",
      width: "35%",
      render: (_, record) => {
        if (record.isTitle) {
          return (
            <Input
              placeholder='Titre de la ligne'
              className='title-input'
              value={record.title}
              onChange={(e) => setLineTitle(e.currentTarget.value, record.index)}
            />
          )
        }

        return (
          <ArticleRow record={record} onSelect={(article) => setArticle(article, record.index)} />
        )
      },
    },
    {
      title: "Quantité",
      dataIndex: "quantity",
      align: "right",
      width: "10%",
      render: (_, record) => {
        if (record.isTitle) {
          return null
        }
        return (
          <InputNumber
            min={0}
            precision={2}
            value={record.quantity}
            onChange={(quantity) => setQuantity(quantity ?? 0, record.index)}
          />
        )
      },
    },
    {
      title: "Unité",
      dataIndex: "unit",
      align: "right",
      width: "10%",
      render: (t, record) => {
        if (record.isTitle) {
          return null
        }

        return (
          <Select
            showSearch
            value={record.unit.suDisplayName || units[0].label}
            placeholder='U'
            optionFilterProp='label'
            onChange={(_, unit) => {
              if (Array.isArray(unit) || !unit.data) {
                return
              }
              setUnit(unit.data, record.index)
            }}
            options={units}
            title={record.unit.suName}
          />
        )
      },
    },
    {
      title: "Prix Unitaire",
      dataIndex: "price",
      align: "right",
      width: "10%",
      render: (_, record) => {
        if (record.isTitle) {
          return null
        }

        return (
          <InputNumber
            value={record.price}
            addonAfter={"€"}
            parser={(value) => value?.replace(/,/g, ".") as unknown as number}
            precision={2}
            onChange={(price) => setPrice(price ?? 0, record.index)}
            min={0}
          />
        )
      },
    },
    {
      title: "Remise",
      dataIndex: "discount",
      align: "right",
      width: "10%",
      render: (_, record) => {
        if (record.isTitle) {
          return null
        }
        return (
          <InputNumber
            value={
              record.discount.type === DiscountType.AMOUNT && record.discount.value > record.price
                ? record.price
                : record.discount.value
            }
            addonAfter={
              <Select
                value={record.discount.type}
                defaultValue={DiscountType.PERCENT}
                optionFilterProp='label'
                onChange={(type) => setDiscount(record.discount.value, type, record.index)}
                options={Discount.getOptions()}
              />
            }
            onChange={(discount) => setDiscount(discount, record.discount.type, record.index)}
            parser={(value) => Number(value?.replace(/,/g, "."))}
            precision={2}
            max={record.discount.type === DiscountType.AMOUNT ? record.price : 100}
            min={0}
          />
        )
      },
    },
    {
      title: "Taxe",
      dataIndex: "tax",
      align: "left",
      width: "10%",
      render: (_, record) => {
        if (record.isTitle) {
          return null
        }
        return (
          <Select
            showSearch
            value={record.tax.taxeName || taxes[0].label}
            placeholder='TVA 5%'
            optionFilterProp='label'
            onChange={(_, tax) => {
              if (Array.isArray(tax) || !tax.data) {
                return
              }
              setTax(tax.data, record.index)
            }}
            options={taxes}
          />
        )
      },
    },
    {
      title: "Montant",
      dataIndex: "sum",
      align: "right",
      className: "sum",
      render: (_, record) => {
        if (record.isTitle) {
          return null
        }
        const total = record.quantity * record.price
        const discount = record.discount.calculateDiscount(total)
        const finalTotal = total - discount
        const tax = finalTotal * (record.tax.taxeCoeff / 100)
        return <Typography.Text>{Math.max(finalTotal + tax, 0).toFixed(2)}€</Typography.Text>
      },
    },
    {
      dataIndex: "actions",
      className: "actions",
      render: (_, record) => <RowActions record={record} />,
    },
  ]

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record: IRow) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    }
  })

  const handleSave = (row: IRow) => {
    const newData = [...lines]
    const index = newData.findIndex((item) => row.key === item.key)
    const item = newData[index]
    newData.splice(index, 1, {
      ...item,
      ...row,
    })
    setLines(newData)
  }

  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext items={lines.map((i) => i.key)} strategy={verticalListSortingStrategy}>
        <Table
          scroll={{ x: 300 }}
          rowKey='key'
          components={{ body: { row: CustomRow } }}
          columns={columns as ColumnTypes}
          dataSource={lines}
          bordered
          pagination={false}
          size='small'
          rowClassName={(record) => (record.title || record.title === "" ? "title-row" : "")}
        />
      </SortableContext>
    </DndContext>
  )
}

interface RowContextProps {
  setActivatorNodeRef?: (element: HTMLElement | null) => void
  listeners?: SyntheticListenerMap
}

const RowContext = React.createContext<RowContextProps>({})

const DragHandle: React.FC<{ disabled: boolean }> = ({ disabled }) => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext)

  return disabled ? (
    <Button disabled={disabled} type='text' size='small' icon={<HolderOutlined />} />
  ) : (
    <Button
      disabled={disabled}
      type='text'
      size='small'
      icon={<HolderOutlined />}
      style={{ cursor: "move" }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  )
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string
}

const CustomRow: React.FC<RowProps> = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: props["data-row-key"] })

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
  }

  const contextValue = useMemo<RowContextProps>(
    () => ({ setActivatorNodeRef, listeners }),
    [setActivatorNodeRef, listeners],
  )

  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  )
}

interface RowActionsProps {
  record: IRow
}

const RowActions: React.FC<RowActionsProps> = ({ record }) => {
  const { lines, addLine, addTitle, setLines, setArticleDescription } = useDevisContext()

  const handleDelete = (key: React.Key) => {
    const newData = lines.filter((item) => item.key !== key)
    setLines(newData, true)
  }

  const actions = useMemo(() => {
    const defaultActions: ItemType[] = []

    if (!record.isTitle && (!record.article.artDesc || record.article.artDesc === "")) {
      defaultActions.push(
        {
          key: "4",
          label: "Ajouter une description",
          onClick: () => setArticleDescription("Ceci est une description", record.index),
        },
        { type: "divider" },
      )
    }

    defaultActions.push(
      {
        key: "1",
        label: "Dupliquer cette ligne",
        onClick: () => addLine(cloneRow(record), record.index),
      },
      { type: "divider" },
      {
        key: "2",
        label: "Ajouter une ligne",
        onClick: () => addLine(undefined, record.index),
      },
      {
        key: "3",
        label: "Ajouter un titre",
        onClick: () => addTitle("", record.index),
      },
    )

    return defaultActions
  }, [record.isTitle, record.article?.artDesc, record.index])

  return (
    <Flex justify='space-evenly'>
      <Dropdown
        className='devis-row-actions'
        overlayClassName='devis-row-actions-overlay'
        menu={{
          items: actions,
        }}
        placement='bottomRight'
        trigger={["click"]}
      >
        <MenuDotsVertical size={16} />
      </Dropdown>

      {lines.length > 1 && (
        <X
          size={16}
          color='#ff4d4f'
          className='remove-line'
          onClick={() => handleDelete(record.key)}
        />
      )}
    </Flex>
  )
}
