import { ITag } from "./ITag"

interface IArticleBase {
  IDArt: number
  artType: string
  artName: string
  artRef: string
  artDesc: string
  artPVHT: number
}

interface SaleUnit {
  IDSaleUnit: number
  suName: string
  suDisplayName: string
}

interface Taxe {
  IDTaxe: number
  taxeName: string
  taxeCoeff: number
}

export interface IArticleFromAPI extends IArticleBase {
  _tags: ITag[]
  _taxe: Taxe
  _saleUnit: SaleUnit
}

export interface IArticle extends IArticleBase {
  tags: ITag[]
  taxe: Taxe
  saleUnit: SaleUnit
}

export class Article implements IArticle {
  IDArt: number
  artType: string
  artName: string
  artRef: string
  artDesc: string
  artPVHT: number
  tags: ITag[]
  taxe: Taxe
  saleUnit: SaleUnit

  constructor(article: IArticleFromAPI) {
    this.IDArt = article.IDArt
    this.artType = article.artType
    this.artName = article.artName
    this.artRef = article.artRef
    this.artDesc = article.artDesc
    this.artPVHT = article.artPVHT
    this.tags = article._tags
    this.taxe = article._taxe
    this.saleUnit = article._saleUnit
  }
}
