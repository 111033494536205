import { IContact } from "../../../types/IContact"

interface IDevisContact {
  IDTiers: number
  tiersDenomination: string
  formattedAddress: string
  codePostal: number
  ville: string
  displayName: string
}

export class DevisContact implements IDevisContact {
  IDTiers: number
  tiersDenomination: string
  formattedAddress: string
  codePostal: number
  ville: string
  displayName: string

  constructor(data: IContact) {
    this.IDTiers = data.IDTiers
    this.tiersDenomination = data.denomination
    this.formattedAddress = data.formattedAddress
    this.codePostal = data.codePostal
    this.ville = data.ville
    this.displayName = `${data.denomination}, ${data.formattedAddress}`
  }
}

export default IDevisContact
