import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, HttpStatusCode } from "axios"
import { IResponse } from "../types/IResponse"

class Fetcher {
  private url: string
  private token: string

  constructor(url: string, token: string) {
    this.url = url
    this.token = token
  }

  public async makeRequest<T>(options: AxiosRequestConfig): Promise<IResponse<T>> {
    try {
      const response = await axios(options)
      return this.formatResponse<T>(response, true)
    } catch (error_) {
      const error = error_ as any

      return this.handleError<T>(error as AxiosError)
    }
  }

  public async get<T>(
    uri: string,
    params: object = {},
    headers: object = {},
    isAuth = true,
  ): Promise<IResponse<T>> {
    const options: AxiosRequestConfig = {
      url: this.buildURL(uri, params),
      method: "get",
      headers: this.getFinalHeaders(headers, isAuth),
    }

    return this.makeRequest<T>(options)
  }

  public async post<T>(
    uri: string,
    body: object | string = {},
    headers: object = {},
    isAuth = true,
    isUrlEncoded = true,
  ): Promise<IResponse<T>> {
    const options: AxiosRequestConfig = {
      url: this.buildURL(uri, {}),
      method: "post",
      headers: this.getFinalHeaders(headers, isAuth),
      data: isUrlEncoded && typeof body === "object" ? this.buildURLEncodedData(body) : body,
    }

    return this.makeRequest<T>(options)
  }

  public async patch<T>(
    uri: string,
    body: object = {},
    headers: object = {},
    isAuth = true,
    isUrlEncoded = true,
  ): Promise<IResponse<T>> {
    const options: AxiosRequestConfig = {
      url: this.buildURL(uri, {}),
      method: "patch",
      headers: this.getFinalHeaders(headers, isAuth),
      data: isUrlEncoded ? this.buildURLEncodedData(body) : body,
    }

    return this.makeRequest<T>(options)
  }

  public async put<T>(
    uri: string,
    body: object = {},
    headers: object = {},
    isAuth = true,
    isUrlEncoded = true,
  ): Promise<IResponse<T>> {
    const options: AxiosRequestConfig = {
      url: this.buildURL(uri, {}),
      method: "put",
      headers: this.getFinalHeaders(headers, isAuth),
      data: isUrlEncoded ? this.buildURLEncodedData(body) : body,
    }

    return this.makeRequest<T>(options)
  }

  public async delete<T>(
    uri: string,
    params: object = {},
    headers: object = {},
    isAuth = true,
  ): Promise<IResponse<T>> {
    const options: AxiosRequestConfig = {
      url: this.buildURL(uri, params),
      method: "delete",
      headers: this.getFinalHeaders(headers, isAuth),
    }

    return this.makeRequest<T>(options)
  }

  private getFinalHeaders(headers: object, isAuth: boolean): object {
    let finalHeaders = { ...headers }

    isAuth = isAuth && this.token !== null

    if (isAuth) {
      finalHeaders = {
        Token: this.token,
        ...headers,
      }
    }
    return finalHeaders
  }

  private buildURL(pathname: string, search: object) {
    return this.url + pathname + this.buildURLParamsFromObject(search)
  }

  private isRequestSuccess = (code: number): boolean => {
    return code >= 200 && code < 300
  }

  private formatResponse<T>(
    axiosResponse: AxiosResponse<any, any>,
    noMessage = false,
  ): IResponse<T> {
    const data = axiosResponse.data.data ?? axiosResponse.data

    if (noMessage) {
      return {
        ok: this.isRequestSuccess(axiosResponse.status),
        code: axiosResponse.status,
        data,
      }
    }

    return {
      ok: this.isRequestSuccess(axiosResponse.status),
      message: axiosResponse.statusText,
      code: axiosResponse.status,
      data,
    }
  }

  private buildURLParamsFromObject(params: object): string {
    if (Object.keys(params).length > 0) {
      const strParams: Record<string, string> = {}
      for (const [key, value] of Object.entries(params)) {
        if (value?.toString()?.length > 0) {
          strParams[key] = value.toString()
        }
      }
      return "?" + new URLSearchParams(strParams).toString()
    }
    return ""
  }

  private buildURLEncodedData = (data: object) => {
    return new URLSearchParams(
      Object.entries(data).map(([key, value]) => [key ?? "", value ?? ""]),
    ).toString()
  }

  private handleError<T>(error: AxiosError): Promise<IResponse<T>> {
    const message = ((error?.response?.data as any) ?? { message: "" })?.message

    if (error?.response)
      return Promise.reject({
        message,
        ok: false,
        code: error?.response?.status,
      })
    return Promise.reject({
      message: error?.message,
      ok: false,
      code: HttpStatusCode.InternalServerError,
    })
  }
}

const NEELAPS_API_URL =
  (process.env.REACT_APP_ENV === "DEV"
    ? process.env.REACT_APP_DEV_API_URL
    : process.env.REACT_APP_PROD_API_URL) ?? ""

const NDF_API_URL =
  (process.env.REACT_APP_ENV === "DEV"
    ? process.env.REACT_APP_DEV_NDF_URL
    : process.env.REACT_APP_PROD_NDF_URL) ?? ""

const NEELAPS_TOKEN = process.env.REACT_APP_DEV_TOKEN ?? ""
const NDF_TOKEN = process.env.REACT_APP_DEV_NDF_TOKEN ?? ""

export const APIFetcher = new Fetcher(NEELAPS_API_URL, NEELAPS_TOKEN)
export const NDFFetcher = new Fetcher(NDF_API_URL, NDF_TOKEN)
