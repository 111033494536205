import { EContent } from "../types/EContent"
import { IResponse } from "../types/IResponse"
import { ITag } from "../types/ITag"
import { NDFFetcher } from "./Fetcher"

const BASE_URL = "tags"

export class TagAPI {
  static getTags = (module: EContent) => {
    return NDFFetcher.get<ITag[]>(`/${module}/${BASE_URL}`)
  }

  static createTag = (tag: Omit<ITag, "IDTag">, module: EContent): Promise<IResponse<ITag>> => {
    return NDFFetcher.post(`/${module}/${BASE_URL}`, tag)
  }

  static updateTag = (tag: Omit<ITag, "deletable">, module: EContent): Promise<IResponse<ITag>> => {
    return NDFFetcher.put<ITag>(`/${module}/${BASE_URL}`, tag)
  }

  static deleteTag = (id: number, module: EContent): Promise<IResponse<ITag>> => {
    return NDFFetcher.delete<ITag>(`/${module}/${BASE_URL}`, { id })
  }
}
