import { Button } from "antd"
import { Trash } from "react-flaticons"
import "./buttonDelete.scss"

interface Props {
  onClick: () => void
  size?: "small" | "middle" | "large"
  iconSize?: number
  className?: string
}

export const ButtonDelete = ({ onClick, size = "middle", iconSize = 20, className }: Props) => {
  return (
    <Button
      onClick={onClick}
      size={size}
      icon={<Trash size={iconSize} />}
      className={`delete-button ${className}`}
    ></Button>
  )
}
