import { useState } from "react"
import { ContactForm } from "../components/ContactForm/ContactForm"
import { ContactTable } from "../components/ContactTable/ContactTable"

export const Contacts: React.FC = () => {
  const [showForm, setShowForm] = useState(false)
  const [selectedContactId, setSelectedContactId] = useState<number>()

  return (
    <div>
      <ContactTable
        refresh={showForm}
        onNewContact={() => setShowForm(true)}
        onEditContact={(contactId) => {
          setSelectedContactId(contactId)
          setShowForm(true)
        }}
      />
      <ContactForm
        isOpen={showForm}
        onClose={() => {
          setShowForm(false)
          setSelectedContactId(undefined)
        }}
        onSubmit={() => {
          setShowForm(false)
          setSelectedContactId(undefined)
        }}
        id={selectedContactId}
      />
    </div>
  )
}
