import IDevisContact from "../modules/devis/models/IDevisContact"
import { EContent } from "../types/EContent"
import { BasicContact, IBasicContactFromAPI } from "../types/IBasicContact"
import { ICompany } from "../types/ICompany"
import { Contact, IContactFromAPI } from "../types/IContact"
import { IContactAddress, ISimpleContactAddress } from "../types/IContactAddress"
import { ILinkedContact, ILinkedTiers } from "../types/ILinkedContact"
import { NDFFetcher } from "./Fetcher"
import { UtilAPI } from "./Utils.api"

const BASE_URI = "/contacts"

export class ContactAPI {
  static getContacts = async () => {
    return NDFFetcher.get<IBasicContactFromAPI[]>(`${BASE_URI}/contacts`).then((res) => {
      const contacts = Object.values(res.data).map((contact) => {
        return new BasicContact(contact)
      })

      return {
        ...res,
        data: contacts,
      }
    })
  }

  static getContact = async (contactId: number) => {
    return NDFFetcher.get<IContactFromAPI>(`${BASE_URI}/contacts`, { id: contactId }).then(
      (res) => ({
        ...res,
        data: new Contact(res.data),
      }),
    )
  }

  static getAddresses = async (contactId: number) => {
    return NDFFetcher.get<ISimpleContactAddress[]>(`${BASE_URI}/address`, {
      idTiers: contactId,
    })
  }

  static getAddress = async (contactId: number) => {
    return NDFFetcher.get<IContactAddress>(`${BASE_URI}/address`, { id: contactId })
  }

  static createAddress = async (
    address: { IDTiers: number } & Omit<IContactAddress, "IDAddress">,
  ) => {
    return NDFFetcher.post(`${BASE_URI}/address`, address)
  }

  static updateAddress = async (address: { IDTiers: number } & IContactAddress) => {
    return NDFFetcher.put(`${BASE_URI}/address`, address)
  }

  static deleteAddress = async (id: number) => {
    return NDFFetcher.delete(`${BASE_URI}/address`, { id })
  }

  static getContactPageContent = () => {
    return UtilAPI.getPageContent(EContent.CONTACTS)
  }

  static getLinkedContactDescription = () => {
    return UtilAPI.getPageContent(EContent.LINKED_CONTACTS)
  }

  static searchForContact = (search: string) => {
    return NDFFetcher.get<IDevisContact[]>(`${BASE_URI}/search?by=name&q=${search}`)
  }

  static getCompanies = () => {
    return NDFFetcher.get<ICompany[]>(`${BASE_URI}/search?by=contactType&q=entreprise`)
  }

  static removeCompanyPersonRelation = (idTiers: number, idContact: number) => {
    return NDFFetcher.delete(`${BASE_URI}/relations`, {
      idTiers,
      idContact,
      contactEnt: 1,
    })
  }

  static createContact = async (contact: any) => {
    return NDFFetcher.post<IContactFromAPI>(`${BASE_URI}/contacts`, { ...contact }).then((res) => ({
      ...res,
      data: new Contact(res.data),
    }))
  }

  static updateContact = async (contact: any) => {
    return NDFFetcher.put<IContactFromAPI>(`${BASE_URI}/contacts`, { ...contact }).then((res) => ({
      ...res,
      data: new Contact(res.data),
    }))
  }

  static deleteContact = async (contactId: number) => {
    return NDFFetcher.delete(`${BASE_URI}/contacts`, { id: contactId })
  }

  static getLinkedContacts = async (id: number) => {
    return NDFFetcher.get<ILinkedContact[]>(
      `${BASE_URI}/relations?idTiers=${id}&action=getList4Tiers`,
    )
  }

  static getAvailableLinkedContacts = async (id: number) => {
    return NDFFetcher.get<ILinkedTiers[]>(`${BASE_URI}/relations?idTiers=${id}&action=getAll`)
  }

  static getLinkedContact = async (idTiers: number, idContact: number) => {
    return NDFFetcher.get<ILinkedContact>(
      `${BASE_URI}/relations?idContact=${idContact}&idTiers=${idTiers}&action=getSingle`,
    )
  }

  static linkContact = async (fromId: number, contact: any) => {
    delete contact.fonction
    delete contact.tags
    delete contact.website
    delete contact.IDEnt
    return NDFFetcher.post(`${BASE_URI}/relations`, {
      idTiers: fromId,
      ...contact,
    })
  }

  static updatedLinkedContact = async (fromId: number, toId: number, contact: any) => {
    delete contact.fonction
    delete contact.tags
    delete contact.website
    delete contact.IDEnt
    return NDFFetcher.put(`${BASE_URI}/relations`, {
      idTiers: fromId,
      idContact: toId,
      ...contact,
    })
  }

  static unlinkContact = async (fromId: number, toId: number) => {
    return NDFFetcher.delete(`${BASE_URI}/relations`, {
      idTiers: fromId,
      idContact: toId,
      contactEnt: 1,
    })
  }
}
