export class EDevisStatus {
  static NEW = { name: "Nouveau", value: "nouveau", color: "#2db7f5" }
  static SENT = { name: "Envoyé", value: "envoyé", color: "#108ee9" }
  static ACCEPTED = { name: "Accepté", value: "accepté", color: "#87d068" }
  static INVOICE = { name: "Facture", value: "facturé", color: "#76ba59" }
  static REFUSED = { name: "Refusé", value: "refusé", color: "#f50" }

  name: string
  color: string
  value: string

  constructor(name: string) {
    const status = EDevisStatus.getStatus(name)
    if (status == null) {
      throw new Error("Invalid status")
    }
    this.name = status.name
    this.color = status.color
    this.value = status.value
  }

  static getStatus(value: string) {
    switch (value) {
      case this.NEW.value:
        return this.NEW
      case this.SENT.value:
        return this.SENT
      case this.ACCEPTED.value:
        return this.ACCEPTED
      case this.INVOICE.value:
        return this.INVOICE
      case this.REFUSED.value:
        return this.REFUSED
    }
  }
}
