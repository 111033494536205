import { ITag } from "./ITag"

export interface IBasicArticleBase {
  IDArt: number
  artType: string
  artName: string
  artRef: string
  artDesc: string
  artPVHT: number
  taxeName: string
  suName: string
  suDisplayName: string
}

export interface IBasicArticleFromAPI extends IBasicArticleBase {
  _tags: ITag[]
}

export interface IBasicArticle extends IBasicArticleBase {
  tags: ITag[]
}

export class BasicArticle implements IBasicArticle {
  IDArt: number
  artType: string
  artName: string
  artRef: string
  artDesc: string
  artPVHT: number
  taxeName: string
  suName: string
  suDisplayName: string
  tags: ITag[]

  constructor(article: IBasicArticleFromAPI) {
    this.IDArt = article.IDArt
    this.artType = article.artType
    this.artName = article.artName
    this.artRef = article.artRef
    this.artDesc = article.artDesc
    this.artPVHT = article.artPVHT
    this.taxeName = article.taxeName
    this.suName = article.suName
    this.suDisplayName = article.suDisplayName
    this.tags = article._tags
  }
}