import { useContext } from "react"
import { Highlight } from "../../../../shared/utils/components/Highlight/Highlight"
import { ISetting } from "../../models/ISetting"
import { SettingContext } from "../drawer/SettingDrawer"

interface Props {
  setting: ISetting
  highlightWord: string | undefined
}

export const Setting: React.FC<Props> = ({ setting, highlightWord }) => {
  const selectSetting = useContext(SettingContext)

  return (
    <li onClick={() => selectSetting(setting)}>
      <Highlight textToHighlight={setting?.label} search={highlightWord || ""} />
    </li>
  )
}
