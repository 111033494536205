import { useState } from "react"
import { ESuiviObject } from "../../../types/ESuiviObject"
import IFormProps from "../../../types/IFormProps"
import { NDFForm } from "../../shared/form/pages/Form/Form"
import { useDevisContext } from "../context/devis.context"
import { CreateUpdateDevis, IDevisForm } from "../models/ICreateUpdateDevis"
import { IDevis } from "../models/IDevis"
import { useDevis } from "../stores/Devis.store"
import { DevisFormContent } from "./DevisFormContent/DevisFormContent"
import { DevisAPI } from "../services/Devis.api"

export const DevisForm: React.FC<IFormProps<IDevis>> = ({ id, isOpen, onSubmit, onClose }) => {
  const [devisId, setDevisId] = useState(id)
  const [loading, setLoading] = useState(true)
  const devis = useDevis()
  const isEdition = !!devisId

  const { lines, contact } = useDevisContext()

  const onFormSubmit = async (devisForm: IDevisForm) => {
    console.log(contact);

    const devis = new CreateUpdateDevis(devisForm, contact, lines)
    console.log(devis)

    DevisAPI.createDevis(devis)

    // onSubmit()
    return true
  }

  const handleOnClose = () => {
    setDevisId(undefined)
    onClose()
  }

  return (
    <NDFForm
      title={isEdition ? "Mise à jour" : "Nouveau devis"}
      onClose={handleOnClose}
      onSubmit={onFormSubmit}
      next={() => {
        const currentDevisIndex = devis.findIndex((c) => c.IDDevis === devisId)
        const nextDevis =
          devis.length === currentDevisIndex + 1 ? devis.at(0) : devis.at(currentDevisIndex + 1)
        if (nextDevis) {
          setDevisId(nextDevis.IDDevis)
        }
      }}
      previous={() => {
        const currentDevisIndex = devis.findIndex((c) => c.IDDevis === devisId) - 1
        const nextDevis = devis.at(currentDevisIndex)
        if (nextDevis) {
          setDevisId(nextDevis.IDDevis)
        }
      }}
      isOpen={isOpen}
      loading={loading}
      actions={[]}
      onActionClick={() => {}}
      objectId={devisId}
      activityType={ESuiviObject.DEVIS}
    >
      <DevisFormContent id={devisId} onLoad={setLoading} />
    </NDFForm>
  )
}
