import { App as AntApp, ConfigProvider } from 'antd'
import { useEffect, useLayoutEffect } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { authRoute, noAuthRoute } from './config/routes'
import { Layout } from './modules/shared/utils/components/Layout/Layout'
import { Guardian } from './services/Guardian'
import { renderRoute } from './services/route.utils'
import { storeToken } from './stores/User.store'

function App() {
  const PUBLIC_HOME = '/public/login/'

  // This is used to access the antd notification and message components, without having a static access error by antd
  useLayoutEffect(() => {
    ConfigProvider.config({
      holderRender: (children) => (
        <ConfigProvider prefixCls='static'>
          <AntApp message={{ maxCount: 1 }} notification={{ maxCount: 5 }}>
            {children}
          </AntApp>
        </ConfigProvider>
      ),
    })
  }, [])

  useEffect(() => {
    // TODO remove for production
    if (process.env.NODE_ENV === 'development') {
      storeToken(process.env.REACT_APP_DEV_TOKEN ?? '')
    }
  }, [])

  return (
    <BrowserRouter>
      <Guardian authRedirect={PUBLIC_HOME} noAuthRedirect={PUBLIC_HOME}>
        <Routes>
          {/* no auth routes */}
          <Route path='/public/'>
            {noAuthRoute.map((route) => renderRoute({ ...route, needAuth: false }))}
          </Route>

          {/* auth routes */}
          <Route path='/auth/' element={<Layout />}>
            {authRoute.map((route) => renderRoute({ ...route, needAuth: true }))}
          </Route>
          <Route path='/*' element={<Navigate to={PUBLIC_HOME} />} />
        </Routes>
      </Guardian>
    </BrowserRouter>
  )
}

export default App
