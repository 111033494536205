import { Avatar, Flex, notification } from "antd"
import TextArea from "antd/es/input/TextArea"
import { SuiviAPI } from "../../../../../../services/Suivi.api"
import { useUser } from "../../../../../../stores/User.store"
import { ESuiviObject } from "../../../../../../types/ESuiviObject"
import { ESuiviTypes } from "../../../../../../types/ESuiviTypes"
import { ISuivi } from "../../../../../../types/ISuivi"
import { FormActivityEntry } from "../FormActivityEntry/FormActivityEntry"
import { FormActivityInput } from "../FormActivityInput/FormActivityInput"
import "./formActivityBody.scss"

interface IFormActivityBodyProps {
  history: ISuivi[]
  readonly?: boolean
  objectId: number | undefined
  formType: ESuiviObject
  loadData: () => void
}

export const FormActivityBody: React.FC<IFormActivityBodyProps> = ({
  history,
  readonly = false,
  objectId,
  formType,
  loadData,
}) => {
  const user = useUser()
  const sendComment = (comment: string) => {
    if (comment && comment.length > 0 && objectId) {
      SuiviAPI.createComment(ESuiviTypes.COMMENT, formType, objectId, comment)
        .then(() => {
          loadData()
        })
        .catch(() => {
          notification.error({
            message: "Erreur",
            description: "Impossible d'ajouter le commentaire",
          })
        })
    } else {
      notification.error({
        message: "Erreur",
        description: "Le commentaire ne peut pas être vide",
      })
    }
  }

  return (
    <div className='form-activity-body'>
      {!readonly && (
        <Flex className='new-comment'>
          <Avatar size={50} src={user.userAvatar} />
          <FormActivityInput submit={sendComment}>
            <TextArea
              autoSize={{ minRows: 3, maxRows: 3 }}
              placeholder='Ajouter un commentaire...'
            />
          </FormActivityInput>
        </Flex>
      )}
      {history.map((item, i) => (
        <FormActivityEntry key={i} entry={item} readonly={readonly} submit={sendComment} />
      ))}
    </div>
  )
}
