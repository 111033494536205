import { EContent } from '../types/EContent'
import { ILoginResponse } from '../types/ILoginResponse'
import { APIFetcher } from './Fetcher'
import { UtilAPI } from './Utils.api'

export class LoginAPI {
  static getLoginPageContent = () => {
    return UtilAPI.getPageContent(EContent.LOGIN)
  }

  static login = (login: string, password: string) => {
    return APIFetcher.post<ILoginResponse>('/users/login', { login, password }, {}, true, true)
  }
}
