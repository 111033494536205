import { Form } from 'antd'
import TextArea from 'antd/es/input/TextArea'

export const InternalNotes: React.FC = () => {
  return (
    <Form.Item name='notes' className='internal-notes'>
      <TextArea rows={10} />
    </Form.Item>
  )
}
