import { SettingFilled } from "@ant-design/icons"
import { Button, Col, Form, Input, InputNumber, Modal, Row, Select, Space, Typography } from "antd"
import dayjs from "dayjs"

import { useEffect, useState } from "react"
import { IDevisNumbering } from "../../models/IDevisNumbering"
import { DevisAPI } from "../../services/Devis.api"

import { NumberingPattern } from "../../models/ENumberingPattern"
import "./devisNumbering.scss"

interface Props {
  numbering: IDevisNumbering
  onClose: () => void
}

const renderPreview = (prefix: string, pattern: string, startNumber: number) => {
  if (prefix && pattern && startNumber) {
    const dateFormat = pattern.replaceAll("X", "")
    let date = ""
    if (dateFormat) {
      date = dayjs().format(dateFormat)
    }
    return `${prefix}${date}${String(startNumber).padStart(4, "0")}`
  }
  return ""
}

export const DevisNumbering: React.FC<Props> = (props) => {
  const [show, setShow] = useState(false)

  return (
    <>
      <Form.Item
        label='N° du devis'
        rules={[
          {
            required: true,
            message: "Veuillez renseigner le numéro du devis",
          },
        ]}
      >
        <Space.Compact style={{ width: "100%" }} onClick={() => setShow(true)}>
          <Input
            value={renderPreview(
              props.numbering.prefixDV,
              props.numbering.typeNum_DV,
              props.numbering.numAuto_DV,
            )}
            name='number'
          />
          <Button type='default'>
            <SettingFilled onClick={() => setShow(true)} />
          </Button>
        </Space.Compact>
      </Form.Item>
      <DevisNumberingSetting
        open={show}
        onClose={() => {
          setShow(false)
          props.onClose()
        }}
        numbering={props.numbering}
      />
    </>
  )
}

interface IDevisNumberingSetting {
  numbering: IDevisNumbering
  open: boolean
  onClose: () => void
}

interface IDevisNumberingSettingForm {
  prefix: string
  pattern: string
  startNumber: number
  preview: string
}

export const DevisNumberingSetting = (props: IDevisNumberingSetting) => {
  const [form] = Form.useForm<IDevisNumberingSettingForm>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    form.setFieldsValue({
      prefix: props.numbering.prefixDV ?? "YYMMXXXX",
      pattern: props.numbering.typeNum_DV ?? "DV-",
      startNumber: props.numbering.numAuto_DV ?? 1,
    })
  }, [props.open, props.numbering])

  const preview = Form.useWatch(
    (values) => renderPreview(values.prefix, values.pattern, values.startNumber),
    form,
  )

  const patternDescription = Form.useWatch(
    (values) => NumberingPattern.getPattern(values.pattern).description,
    form,
  )

  const onSubmit = () => {
    setLoading(true)

    const formData = form.getFieldsValue()

    DevisAPI.createNumbering(formData.prefix, formData.pattern, formData.startNumber)
      .then(() => {
        onClose()
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onClose = () => {
    props.onClose()
    form.resetFields()
  }

  return (
    <Modal
      title='Type de numérotation'
      open={props.open}
      onCancel={onClose}
      onOk={onSubmit}
      okText='Valider'
      cancelText='Annuler'
      className='devis-numbering-modal'
      width={"40%"}
      confirmLoading={loading}
    >
      <Form
        form={form}
        labelCol={{ xs: 24, sm: 5, md: 6, lg: 5, xl: 7, xxl: 6 }}
        labelWrap
        labelAlign='left'
        layout='horizontal'
      >
        <Row>
          <Col xs={24} className='numbering-explanation'>
            <Typography.Text>
              Les numéros de devis sont paramétrés pour être générés automatiquement. Vous pouvez
              choisir ci-dessous différents modèles de numérotation.
              <br />
              <b>
                Attention, une fois votre premier devis créé, vous ne pourrez plus revenir sur cette
                configuration.
              </b>
            </Typography.Text>
          </Col>
          <Col xs={24} className='numbering-pattern'>
            <Form.Item
              label='Type de numérotation'
              rules={[
                {
                  required: true,
                  message: "Veuillez renseigner le numéro de départ de la numérotation",
                },
              ]}
              name='pattern'
            >
              <Select
                defaultValue={props.numbering.typeNum_DV}
                options={NumberingPattern.getAll()}
              />
            </Form.Item>
            <Form.Item colon={false} label=' '>
              <Typography.Text className='pattern-description'>
                {patternDescription}
              </Typography.Text>
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              label='Préfixe'
              rules={[
                {
                  required: true,
                  message: "Veuillez renseigner le préfixe de la numérotation",
                },
              ]}
              name='prefix'
            >
              <Input defaultValue={props.numbering.prefixDV} />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              label='Numéro auto'
              rules={[
                {
                  required: true,
                  message: "Veuillez renseigner le numéro de départ de la numérotation",
                },
              ]}
              name='startNumber'
            >
              <InputNumber
                defaultValue={props.numbering.numAuto_DV}
                style={{ width: "100%" }}
                max={9999}
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item label='Prévisualisation'>
              <Input disabled value={preview} className='numbering-preview' />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
