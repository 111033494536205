import { ISaleUnit } from "../../../types/ISaleUnit"
import { SearchArticle } from "../../../types/ISearchArticle"
import { ITaxe } from "../../../types/ITaxe"
import { EDevisStatus } from "./EDevisStatus"

export interface IDevis {
  IDDevis: number
  creatorName: string
  numPiece: string
  isModele: number
  datePiece: string
  dateExpiration: string
  devisStatut: EDevisStatus
  devisTitle: string
  devisTotalHT: number
  devisTotalTaxes: number
  devisTotalTTC: number
  devisCGV: string
  IDTiers: number
  tiers_displayName: string
  tiers_formattedAddress: string
  tiers_adresse: string
  tiers_codePostal: number
  tiers_ville: string
  tiers_countryCode: string
  tiers_countryName: string
  _lignes: Ligne[][]
}

interface Ligne {
  IDDevisLigne: number
  IDArt: number
  IDTaxe: number
  IDSaleUnit: number
  numChapitre: number
  numLigne: number
}

export class DiscountType {
  static PERCENT = "P"
  static AMOUNT = "V"
}

export class Discount {
  type: string
  value: number

  constructor(type: DiscountType, coefficient: number) {
    this.type = type.toString()
    this.value = coefficient
  }

  calculateDiscount(price: number) {
    return this.type === DiscountType.PERCENT ? price * (this.value / 100) : this.value
  }

  getType() {
    return this.type === DiscountType.PERCENT ? "%" : "€"
  }

  getTypeLabel() {
    return this.type === DiscountType.PERCENT ? "P" : "V"
  }

  getRealType() {
    return this.type === DiscountType.PERCENT ? DiscountType.PERCENT : DiscountType.AMOUNT
  }

  private static getLabel(type: string) {
    return type === DiscountType.PERCENT ? "%" : "€"
  }

  static getOptions() {
    return [
      { value: DiscountType.PERCENT, label: Discount.getLabel(DiscountType.PERCENT) },
      { value: DiscountType.AMOUNT, label: Discount.getLabel(DiscountType.AMOUNT) },
    ]
  }
}

export interface IRow {
  key: string

  IDDevisLigne?: number

  index: number

  title?: string
  isTitle: boolean

  article: SearchArticle

  quantity: number

  unit: ISaleUnit

  price: number
  discount: Discount

  tax: ITaxe
}
