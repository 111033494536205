import { Button, Divider, Flex, Typography } from "antd"
import { ReactNode } from "react"
import "./noData.scss"

const { Text, Title } = Typography

interface Props {
  title?: string | undefined
  pageDescription?: string | undefined
  pageObjectives?: string | ReactNode | undefined
  whatCanBeDone?: string[]
  onNew: () => void
  onNewText?: string | undefined
}

export const NoData = ({
  pageDescription,
  pageObjectives,
  onNew,
  onNewText,
  title,
  whatCanBeDone = [],
}: Props) => {
  return (
    <Flex vertical justify='center' align='center' className='no-data'>
      <Flex vertical align='center' justify='center' className='description'>
        <Title>{pageDescription}</Title>
        <Text>{pageObjectives}</Text>
      </Flex>
      <div className='actions'>
        <Button type='primary' onClick={onNew}>
          {onNewText}
        </Button>
      </div>
      {whatCanBeDone?.length > 0 && (
        <>
          <Divider />

          <Flex vertical align='center' className='what-can-be-done'>
            <Text>Dans la section {title}, vous pouvez :</Text>
            <ul>
              {whatCanBeDone.map((item, index) => (
                <li key={index}>
                  <Text>{item}</Text>
                </li>
              ))}
            </ul>
          </Flex>
        </>
      )}
    </Flex>
  )
}
