import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { authRoute, noAuthRoute } from '../config/routes'
import { getToken } from '../stores/User.store'
import { IRawRoute } from './route.utils'

interface IGuardianProps {
  noAuthRedirect: string
  authRedirect: string
  children: React.ReactElement
}

const getAuthForRoute = () => {
  const pathname = location.pathname
  const noAuth = noAuthRoute.find((r) =>
    [pathname, `${pathname}/`, pathname.slice(0, -1)].includes('/public/' + r.path),
  )
  const auth = authRoute.find((r) =>
    [pathname, `${pathname}/`, pathname.slice(0, -1)].includes('/auth/' + r.path),
  )

  const actualRoute = {
    ...((noAuth || auth) as IRawRoute),
    needAuth: auth !== undefined,
  }

  return actualRoute.needAuth
}

export const Guardian: React.FC<IGuardianProps> = ({ noAuthRedirect, authRedirect, children }) => {
  const token = getToken()
  const isAuth = token && token.length > 0
  const location = useLocation()

  const routeNeedAuth = getAuthForRoute()

  const navigate = useNavigate()

  let redirectRoute = location.pathname

  if (isAuth !== routeNeedAuth) {
    if (isAuth === true) redirectRoute = authRedirect
    else redirectRoute = noAuthRedirect
  }

  useEffect(() => {
    if (location.pathname !== redirectRoute) {
      navigate(redirectRoute)
    }
  }, [location, isAuth])

  return children
}
