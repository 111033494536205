import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { IBasicContact } from '../types/IBasicContact'

interface UserState {
  contacts: IBasicContact[]
  create: (contacts: IBasicContact[]) => void
  set: (contacts: IBasicContact[]) => void
  delete: () => void
}

export const useContactStore = create<UserState>()(
  persist(
    (set) => ({
      contacts: [],
      create: (contacts) =>
        set({
          contacts: [...contacts],
        }),
      set: (newContacts) => set({ contacts: [...newContacts] }),
      delete: () => set({ contacts: undefined }),
    }),
    { name: 'NeelapsDevisFacturationStore' },
  ),
)

export const useContacts = () => {
  return useContactStore((state) => state.contacts)
}
