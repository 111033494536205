import { Box, Calculator, Download, FileInvoice, Home, UsersAlt } from "react-flaticons"

import { ArticleForm } from "../modules/article/components/ArticleForm/ArticleForm"
import { Articles } from "../modules/article/pages/Articles"
import { ContactForm } from "../modules/contact/components/ContactForm/ContactForm"
import { Contacts } from "../modules/contact/pages/Contacts"
import { Dashboard } from "../modules/dashboard/pages/Dashboard"
import { DevisForm } from "../modules/devis/components/DevisForm"
import { Devis } from "../modules/devis/pages/Devis"
import { Login } from "../modules/login/pages/Login"
import { IRawRoute } from "../services/route.utils"

export const noAuthRoute: IRawRoute[] = [
  {
    path: "login/",
    component: Login,
    group: 0,
  },
]

export const authRoute: IRawRoute[] = [
  {
    name: "Tableau de board",
    path: "dashboard/",
    component: Dashboard,
    icon: Home,
    group: 0,
  },
  {
    name: "Contacts",
    path: "contacts/",
    component: Contacts,
    icon: UsersAlt,
    group: 1,
    Modal: ContactForm,
  },
  {
    name: "Articles",
    path: "articles/",
    component: Articles,
    icon: Box,
    group: 1,
    Modal: ArticleForm,
  },
  {
    name: "Devis",
    path: "devis/",
    component: Devis,
    icon: Calculator,
    group: 2,
    Modal: DevisForm,
  },
  {
    name: "Facture",
    path: "facture/",
    component: Home,
    icon: FileInvoice,
    group: 2,
  },
  {
    name: "Paiements",
    path: "paiements/",
    component: Home,
    icon: Download,
    group: 2,
  },
]
