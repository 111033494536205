import { EContactType } from './EContactType'
import { IBasicCompany } from './IBasicCompany'
import { ITag } from './ITag'

/**
 * This is a contact from get all contacts
 */
export interface IBasicContactFromAPI {
  IDTiers: number
  denomination: string
  formattedAddress: string
  adresse: string
  codePostal: number
  ville: string
  countryName: string
  phone: string
  phone2: string
  email: string
  fonction: string
  _tags: ITag[]
  _entreprise: IBasicCompany
  companyType: EContactType
}

/**
 * This is a formatted contact
 */
export interface IBasicContact {
  IDTiers: number
  denomination: string
  formattedAddress: string
  adresse: string
  codePostal: number
  ville: string
  countryName: string
  phone: string
  phone2: string
  email: string
  fonction: string
  tags: ITag[]
  entreprise: IBasicCompany
  companyType: EContactType
}

export class BasicContact {
  IDTiers: number
  denomination: string
  formattedAddress: string
  adresse: string
  codePostal: number
  ville: string
  countryName: string
  phone: string
  phone2: string
  email: string
  fonction: string
  tags: ITag[]
  entreprise: IBasicCompany
  companyType: EContactType

  constructor(contact: IBasicContactFromAPI) {
    this.IDTiers = contact.IDTiers
    this.denomination = contact.denomination
    this.formattedAddress = contact.formattedAddress
    this.adresse = contact.adresse
    this.codePostal = contact.codePostal
    this.ville = contact.ville
    this.countryName = contact.countryName
    this.phone = contact.phone
    this.phone2 = contact.phone2
    this.email = contact.email
    this.fonction = contact.fonction
    this.tags = contact._tags
    this.entreprise = contact._entreprise
    this.companyType = contact.companyType
  }
}
