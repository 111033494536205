import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { IBasicArticle } from '../types/IBasicArticle'

interface ArticleState {
  articles: IBasicArticle[]
  create: (contacts: IBasicArticle[]) => void
  set: (contacts: IBasicArticle[]) => void
  delete: () => void
}

export const useArticleStore = create<ArticleState>()(
  persist(
    (set) => ({
      articles: [],
      create: (articles) =>
        set({
          articles: [...articles],
        }),
      set: (newArticles) => set({ articles: [...newArticles] }),
      delete: () => set({ articles: undefined }),
    }),
    { name: 'NeelapsDevisFacturationStore' },
  ),
)

export const useArticles = () => {
  return useArticleStore((state) => state.articles)
}
