import { Button, Col, Drawer, Dropdown, Flex, Form, MenuProps, Row, Space, Spin } from "antd"
import React, { useState } from "react"
import { AngleLeft, AngleRight, AngleSmallDown, Settings } from "react-flaticons"
import { MenuInfo } from "../../../../../../node_modules/rc-menu/lib/interface"
import { ESuiviObject } from "../../../../../types/ESuiviObject"
import { ITabs } from "../../../../../types/ITabs"
import { FormActivity } from "../../components/activity/FormActivity/FormActivity"
import { FormSubModule } from "../../components/module/FormSubModule"
import "./form.scss"

interface IFormProps {
  title: string
  children?: React.ReactNode
  onClose: () => void
  onSubmit: (values: any) => Promise<boolean>
  next: () => void
  previous: () => void
  isOpen: boolean
  subModuleTabs?: ITabs[]
  loading: boolean
  actions: MenuProps["items"]
  onActionClick: (action: MenuInfo) => void
  objectId: number | undefined
  activityType: ESuiviObject
}

export const NDFForm: React.FC<IFormProps> = ({
  title,
  onClose,
  onSubmit,
  next,
  previous,
  isOpen,
  children,
  subModuleTabs,
  loading,
  actions,
  onActionClick,
  objectId,
  activityType,
}) => {
  const [submitting, setSubmitting] = useState(false)
  const [form] = Form.useForm()
  const isNew = !objectId

  const submit = async () => {
    // setSubmitting(true)
    console.log(form.getFieldsValue());

    onSubmit(form.getFieldsValue()).then((submitSucceeded) => {
      setSubmitting(false)
      if (submitSucceeded) {
        // setTimeout(() => {
        //   form.resetFields()
        // }, 500)
      }
    })
  }

  const header = (
    <Space>
      {actions?.length ? (
        <>
          <Dropdown menu={{ items: actions, onClick: onActionClick }} trigger={["click"]}>
            <Button className='new-transaction'>
              <Flex justify='center' align='center'>
                Nouvelle transaction
                <AngleSmallDown />
              </Flex>
            </Button>
          </Dropdown>

          <Dropdown menu={{ items: actions, onClick: onActionClick }} trigger={["click"]}>
            <Button icon={<Settings />}></Button>
          </Dropdown>
          <Button onClick={previous} icon={<AngleLeft />} aria-label='Précédent'></Button>
          <Button onClick={next} icon={<AngleRight />} aria-label='Suivant'></Button>
        </>
      ) : null}
      <Button
        loading={submitting}
        onClick={submit}
        htmlType='submit'
        type='primary'
        className='save-button-large-display'
      >
        Enregistrer
      </Button>
    </Space>
  )

  return (
    <Drawer
      keyboard={false}
      open={isOpen}
      width={"90%"}
      onClose={() => {
        onClose()
        form.resetFields()
      }}
      title={
        <Flex justify='space-between' align='center'>
          {title}
          <Button
            // loading={submitting}
            onClick={submit}
            htmlType='submit'
            type='primary'
            className='save-button-small-display'
          >
            Enregistrer
          </Button>
        </Flex>
      }
      extra={header}
      className='form-drawer'
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          labelCol={{ xs: 24, sm: 5, md: 6, lg: 5, xl: 7, xxl: 6 }}
          labelWrap
          labelAlign='left'
          layout='horizontal'
        >
          <Row className='content-container'>
            <Col xs={24} xl={isNew ? 24 : 17} className='content bg-white'>
              <Row>{children}</Row>
              {subModuleTabs?.length && (
                <Row className='sub-modules'>
                  <FormSubModule tabs={subModuleTabs} />
                </Row>
              )}
            </Col>
            <Col flex={"flex"} className={`activity ${isNew ? "hidden" : ""}`}>
              <FormActivity objectId={objectId} type={activityType} />
            </Col>
          </Row>
        </Form>
      </Spin>
    </Drawer>
  )
}
