import { PlusOutlined } from "@ant-design/icons"
import { Button, Col, ColorPicker, Form, Input, notification, Row, Space, Tag } from "antd"
import { useEffect, useState } from "react"
import { TagAPI } from "../../../../../services/Tags.api"
import { EContent } from "../../../../../types/EContent"
import { ITag } from "../../../../../types/ITag"
import { TAG_COLORS } from "./model/tagColors"

interface Props {
  tag?: ITag
  module: EContent
  onNewTag: (tag: ITag) => void
}

export const EditAddTagForm = ({ tag, module, onNewTag: onAddOrUpdateTag }: Props) => {
  const [newTagColor, setNewTagColor] = useState(tag?.tagClassName || "")
  const [form] = Form.useForm()

  useEffect(() => {
    if (tag) {
      form.setFieldsValue({
        tagName: tag?.tagName,
        tagColor: tag?.tagClassName,
      })
    }
  }, [])

  const updateTag = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    const newTag = getTagFromForm(e)

    if (newTag && tag) {
      TagAPI.updateTag({ ...newTag, IDTag: tag.IDTag }, module)
        .then((res) => {
          if (res.ok) {
            onAddOrUpdateTag(res.data)
            notification.success({
              message: "Étiquette mise à jour",
            })
          }
        })
        .catch(() => {
          notification.error({
            message: "Erreur",
            description: "Impossible de mettre à jour l'étiquette.",
          })
        })
    }
  }

  const createTag = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    const newTag = getTagFromForm(e)
    if (newTag) {
      TagAPI.createTag(newTag, module)
        .then((res) => {
          if (res.ok) {
            notification.success({
              message: "Étiquette créée",
            })
            onAddOrUpdateTag(res.data)
          }
        })
        .catch(() => {
          notification.error({
            message: "Erreur",
            description: "Impossible de créer l'étiquette.",
          })
        })
    }
  }

  const getTagFromForm = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    e.preventDefault()

    if (!form.getFieldValue("tagName")) {
      notification.warning({
        message: "Attention",
        description: "Vous ne pouvez pas créer une étiquette sans nom.",
      })
      return
    }

    const newTag = {
      tagName: form.getFieldValue("tagName"),
      tagClassName: form.getFieldValue("tagColor"),
      deletable: 1,
    }
    form.setFieldValue("tagName", "")

    return newTag
  }

  return (
    <>
      <Space style={{ padding: "0 8px 4px" }}>
        <Form style={{ width: "100%" }} form={form}>
          <Row gutter={12} align='middle' justify='space-between'>
            <Col span={12}>
              <Tag color={newTagColor}>
                <Form.Item name='tagName' className='new-tag'>
                  <Input
                    style={{ color: newTagColor }}
                    variant='borderless'
                    placeholder='Étiquette'
                    onKeyDown={(e) => e.stopPropagation()}
                  />
                </Form.Item>
              </Tag>
            </Col>
            <Form.Item name='tagColor' className='new-tag' noStyle>
              <ColorPicker
                trigger='hover'
                panelRender={() => {
                  return (
                    <div style={{ padding: "8px" }}>
                      {TAG_COLORS.map((color) => (
                        <Tag
                          key={color}
                          color={color === "grey" ? "" : color}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            form.setFieldValue("tagColor", color)
                            setNewTagColor(color)
                          }}
                        >
                          {color}
                        </Tag>
                      ))}
                    </div>
                  )
                }}
              >
                <Tag className='new-tag-color-picker' color={newTagColor} />
              </ColorPicker>
            </Form.Item>
            {tag ? (
              <Button type='default' className='update-tag-button' onClick={updateTag}>
                Mettre à jour
              </Button>
            ) : (
              <Button
                type='text'
                className='new-tag-button'
                icon={<PlusOutlined />}
                onClick={createTag}
              >
                Créer
              </Button>
            )}
          </Row>
        </Form>
      </Space>
    </>
  )
}
