export class NumberingPattern {
  static YYMMXXXX = () => {
    return {
      value: "YYMMXXXX",
      label: "Préfixe-YYMMXXXX (par défaut)",
      description:
        "La numérotation du devis se fait sur 2 chiffres de l’année, du numéro du mois et d’un numéro automatique sur 4 chiffres",
    }
  }

  static YYYYXXXX = () => {
    return {
      value: "YYYYXXXX",
      label: "Préfixe-YYYYXXXX",
      description:
        "La numérotation du devis se fait sur 4 chiffres de l’année et d’un numéro automatique sur 4 chiffres",
    }
  }

  static XXXXXX = () => {
    return {
      value: "XXXXXX",
      label: "Préfixe-XXXXXX",
      description: "La numérotation du devis se fait sur un numéro automatique sur 6 chiffres",
    }
  }

  static getAll = () => {
    return [NumberingPattern.YYMMXXXX(), NumberingPattern.YYYYXXXX(), NumberingPattern.XXXXXX()]
  }

  static getPattern = (pattern: string) => {
    switch (pattern) {
      case "YYMMXXXX":
        return NumberingPattern.YYMMXXXX()
      case "YYYYXXXX":
        return NumberingPattern.YYYYXXXX()
      case "XXXXXX":
        return NumberingPattern.XXXXXX()
      default:
        return NumberingPattern.YYMMXXXX()
    }
  }
}
