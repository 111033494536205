import { Flex, Modal, notification, Space, Tag } from "antd"
import { useEffect, useState } from "react"
import { TagAPI } from "../../../../services/Tags.api"
import { EContent } from "../../../../types/EContent"
import { ITag } from "../../../../types/ITag"
import { EditAddTagForm } from "../../../shared/form/components/tags/EditAddTagForm"
import { ButtonDelete } from "../../../shared/utils/components/ButtonDelete/ButtonDelete"
import { ButtonNew } from "../../../shared/utils/components/ButtonNew/ButtonNew"
import { Table } from "../../../shared/utils/components/Table/Table"
import "./contactTagSetting.scss"

const COLUMNS = [
  {
    title: "Étiquette",
    field: "tagName",
  },
  {
    title: "Exemple",
    field: "example",
  },
]

const columns = COLUMNS.map((col) => ({
  key: col.field,
  title: col.title,
  dataIndex: col.field,
  width: `${100 / COLUMNS.length}%`,
  ...(col.field !== "example" && {
    sorter: (a: any, b: any) => {
      const strA = String(a[col.field]).trim().toLowerCase()
      const strB = String(b[col.field]).trim().toLowerCase()
      if (strA === "") return 1
      if (strB === "") return -1
      return strA.localeCompare(strB)
    },
  }),
  render: (value: string, record: ITag) => {
    if (col.field === "example") {
      return <Tag color={record.tagClassName}>{record.tagName}</Tag>
    }
    return <div>{value}</div>
  },
}))

export const ContactTagSetting: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [tags, setTags] = useState<ITag[]>([])
  const [showModal, setShowModal] = useState(false)
  const [selectedTagId, setSelectedTagId] = useState<number>()
  const [selectedTagIds, setSelectedTagIds] = useState<number[]>([])

  useEffect(() => {
    getTags()
  }, [])

  const getTags = () => {
    TagAPI.getTags(EContent.CONTACTS)
      .then((res) => {
        setTags(
          res.data.filter((tag) => tag.deletable === 1).map((tag) => ({ ...tag, key: tag.IDTag })),
        )
      })
      .catch((err) => {
        notification.error({
          message: "Erreur lors de la récupération des étiquettes",
          description: err.message,
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const deleteTags = () => {
    const promises = selectedTagIds.map((id) => TagAPI.deleteTag(id, EContent.CONTACTS))
    Promise.all(promises)
      .then(() => {
        notification.success({
          message: promises.length > 1 ? "Étiquettes supprimées" : "Étiquette supprimée",
        })
      })
      .catch((err) => {
        notification.error({
          message:
            promises.length > 1
              ? "Erreur lors de la suppression des l'étiquettes"
              : "Erreur lors de la suppression de l'étiquette",
          description: err.message,
        })
      })
      .finally(() => {
        getTags()
      })
  }

  return (
    <>
      <Flex>
        <Table
          header={() => (
            <Flex justify='flex-end'>
              <Space>
                <ButtonDelete onClick={deleteTags} />
                <ButtonNew onClick={() => setShowModal(true)} />
              </Space>
            </Flex>
          )}
          loading={loading}
          columnsToDisplay={columns}
          data={tags}
          noData={() => <div>Aucune étiquette</div>}
          onEdit={(tag: ITag) => {
            setSelectedTagId(tag.IDTag)
            setShowModal(true)
          }}
          pagination={false}
          onRowSelection={(selectedRowKeys) => {
            setSelectedTagIds(selectedRowKeys as number[])
          }}
        />
      </Flex>
      {showModal && (
        <Modal
          title={selectedTagId ? "Mettre à jour" : "Créer une étiquette"}
          open={showModal}
          onCancel={() => {
            setShowModal(false)
            setSelectedTagId(undefined)
          }}
          cancelText='Annuler'
          className='contact-tag-setting-modal'
        >
          <EditAddTagForm
            tag={tags.find((tag) => tag.IDTag === selectedTagId)}
            onNewTag={() => {
              getTags()
              setShowModal(false)
              setSelectedTagId(undefined)
            }}
            module={EContent.CONTACTS}
          />
        </Modal>
      )}
    </>
  )
}
